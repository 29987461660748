import { useAuthContext } from "../hooks/useAuthContext";
import { useServiceContext } from "../hooks/useServiceContext";
import { BsTrash } from "react-icons/bs";
import configData from '../config.json';

//date fns
//import formatDistanceToNow from "date-fns/formatDistanceToNow";

const ServiceList = ({ service }) => {
    const { user } = useAuthContext();
    const { dispatch } = useServiceContext();

    const handleClick = async () => {
        if (!user) {
            return;
        }
        const response = await fetch(`${configData.SERVER_URL}/api/services/${service._id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        });
        const json = await response.json();

        if (response.ok) {
            dispatch({ type: "DELETE_SERVICE", payload: json });
        } else if (!response.ok) {
            console.log('error: ', response.error)
        }
    };

    return (
        <li >
            <div>
                <p>
                    {service.serviceName}
                </p>
                <span className="icon" onClick={handleClick}>
                    <BsTrash className="trash-icon" />
                </span>
            </div>

        </li>
    );
};

export default ServiceList;
