import React from 'react'
import SingleProperty from '../components/SingleProperty'

const Property = () => {
  return (
    <div className="single-layout">
<h1>Property</h1>
<SingleProperty />      
</div>

  )
}

export default Property