import { createContext, useReducer } from "react";

export const ServiceContext = createContext();

export const servicesReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_OPTIONS":
            return {
                activeOptions: action.payload,
                services: state.services
            };
        case "CREATE_SERVICE":
            return {
                services: [action.payload, ...state.services],
            };
        case "SET_SERVICES":
            return {
                services: action.payload,
            };
        case "DELETE_SERVICE":
            return {
                services: state.services.filter((o) => o._id !== action.payload._id),
            };

        default:
            return state;
    }
};

export const ServiceContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(servicesReducer, {
        services: null,
    });

    return (
        <ServiceContext.Provider value={{ ...state, dispatch }}>
            {children}
        </ServiceContext.Provider>
    );
};
