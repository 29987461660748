import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import PropDropdown from "../components/PropDropdown";
import { BsPlusCircleFill } from "react-icons/bs";
import configData from '../config.json';

// Hooks
import { useAuthContext } from "../hooks/useAuthContext";

const AddClient = () => {
  const [clientFName, setClientFName] = useState("");
  const [clientLName, setClientLName] = useState("");
  const [clientAddress1, setClientAddress1] = useState("");
  const [clientAddress2, setClientAddress2] = useState("");
  const [clientCity, setClientCity] = useState("");
  const [clientState, setClientState] = useState("");
  const [clientZip, setClientZip] = useState("");
  const [clientPhone1, setClientPhone1] = useState("");
  const [clientPhone2, setClientPhone2] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const [clientID, setClientID] = useState('')

  const { user, dispatch } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      setError("You must be logged in");
      return;
    }
    if (!clientPhone1) {
      if (clientPhone2) {
        var client = { 'ownerFName': clientFName, 'ownerLName': clientLName, 'ownerAddress1': clientAddress1, 'ownerAddress2': clientAddress2, 'ownerCity': clientCity, 'ownerState': clientState, 'ownerZip': clientZip, 'ownerPhone1': clientPhone2, 'ownerPhone2': '', 'ownerEmail': clientEmail }
        setClientPhone1(clientPhone2)
        setClientPhone2('')
      }
    } else {
      var client = { 'ownerFName': clientFName, 'ownerLName': clientLName, 'ownerAddress1': clientAddress1, 'ownerAddress2': clientAddress2, 'ownerCity': clientCity, 'ownerState': clientState, 'ownerZip': clientZip, 'ownerPhone1': clientPhone1, 'ownerPhone2': clientPhone2, 'ownerEmail': clientEmail }
    }
    // const client = { 'ownerFName': clientFName, 'ownerLName': clientLName, 'ownerAddress1': clientAddress1, 'ownerAddress2': clientAddress2, 'ownerCity': clientCity, 'ownerState': clientState, 'ownerZip': clientZip, 'ownerPhone1': clientPhone1, 'ownerPhone2': clientPhone2, 'ownerEmail': clientEmail }
    console.log(client)

    const response = await fetch(`${configData.SERVER_URL}/api/owners`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(client)
    });
    const json = await response.json();

    if (!response.ok) {
      setError(json.error);
      setEmptyFields(json.emptyFields);
    }
    if (response.ok) {
      setClientID(json._id)
      setClientFName("");
      setClientLName("");
      setClientAddress1("");
      setClientAddress2("");
      setClientCity("");
      setClientState("");
      setClientZip("");
      setClientPhone1("");
      setClientPhone2("");
      setClientEmail("");
      setError('');
      setEmptyFields([]);
    }
  }

  useEffect(() => {
    setIsLoading(false)
  }, [dispatch, user]);

  return (
    <div>{!isLoading &&
      <div>
        {!user ? <Navigate to="/login" /> :
          <div>
            <h1>Add Owner</h1>
            <div className="add-property-wrapper">
              <form action="" id="clientForm">
                <div className="new-property-grid">
                  <div className="new-property-grid-col">
                    <label className="new-prop-label-title" htmlFor="">
                      Name
                    </label>
                    <input type="text" placeholder="First Name" onChange={(e) => setClientFName(e.target.value)} value={clientFName} />
                    <input type="text" placeholder="Last Name" onChange={(e) => setClientLName(e.target.value)} value={clientLName} />
                    <br />
                    <label htmlFor="">Address 1</label>
                    <input type="text" onChange={(e) => setClientAddress1(e.target.value)} value={clientAddress1} />
                    <label htmlFor="">Address 2</label>
                    <input type="text" onChange={(e) => setClientAddress2(e.target.value)} value={clientAddress2} />
                    <label htmlFor="">City</label>
                    <input type="text" onChange={(e) => setClientCity(e.target.value)} value={clientCity} />
                    <div className="new-prop-input-2col">
                      <div>
                        <label htmlFor="">State</label>
                        <input className="input-space" type="text" onChange={(e) => setClientState(e.target.value)} value={clientState} />
                      </div>
                      <div>
                        <label htmlFor="">Zip</label>
                        <input type="text" onChange={(e) => setClientZip(e.target.value)} value={clientZip} />
                      </div>
                    </div>
                  </div>
                  <div className="new-property-grid">
                    <div className="new-property-grid-col">
                      <p className="new-prop-label-title">Contact Info</p>
                      <label htmlFor="">Primary Phone</label>
                      <input type="text" onChange={(e) => setClientPhone1(e.target.value)} value={clientPhone1} />
                      <label htmlFor="">Secondary Phone</label>
                      <input type="text" onChange={(e) => setClientPhone2(e.target.value)} value={clientPhone2} />
                      <label htmlFor="">Email</label>
                      <input type="text" onChange={(e) => setClientEmail(e.target.value)} value={clientEmail} />
                    </div>
                  </div>
                  <div className="add-button" onClick={handleSubmit}>
                    <BsPlusCircleFill />
                  </div>
                  <div>
                    <p>{error}</p>
                    {!clientID ? (
                      null
                    ) : (
                      <div>
                        <p>Owner successfully added!</p>
                        <Link className="button" to={`/clients/${clientID}`}>View Client</Link>
                      </div>
                    )}
                    <ul>{emptyFields && emptyFields.map((field) => (<li>{field}</li>))}</ul>
                  </div>
                </div>
              </form>
            </div>
          </div>}
      </div>}
    </div>
  );
};

export default AddClient;
