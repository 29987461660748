import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import $ from "jquery";

//hooks
import { useCalendarContext } from "../hooks/useCalContext.js";
import { useAuthContext } from "../hooks/useAuthContext";

//Components
import CalendarComponent from "../components/CalendarComponent";
import calendarRender from "../components/calendarRender.css";
import NewEvent from '../components/AddEventButton';

const Calendar = () => {
  $(function () {
    $("a").removeClass("current");
  });
  const { calendar, dispatch } = useCalendarContext();
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false)
  }, [dispatch, user]);

  return (
    <div>{!isLoading &&
      <div>
        {!user ? <Navigate to="/login" /> :
          <div>
            <div className="calendar-header">
              <h1>Calendar</h1>
              <NewEvent />
            </div>
            <div className="calendar-details">
              <CalendarComponent />
            </div>
          </div>}
      </div>}
    </div>
  );
};

export default Calendar;
