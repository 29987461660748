import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import configData from '../config.json';

import AddFeature from "../components/AddPropFeature";
import FeatureList from '../components/FeatureList';

// Hooks
import { useAuthContext } from "../hooks/useAuthContext";
import { useFeatureContext } from "../hooks/useFeatureContext";

const PropFeatures = () => {
    const { user } = useAuthContext();
    const { features, dispatch } = useFeatureContext();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false)

        const fetchFeatures = async () => {
            const response = await fetch(`${configData.SERVER_URL}/api/features`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            const json = await response.json();

            if (response.ok) {
                dispatch({ type: "SET_FEATURES", payload: json });
            }
        };

        if (user) {
            fetchFeatures();
        }
    }, [dispatch, user]);

    return (
        <div>{!isLoading &&
            <div className="owner-details">
                {!user ? <Navigate to="/login" /> :
                    <div className="feature-section-wrapper">
                        <AddFeature />
                        {features && <div className="current-feature-wrapper">
                            <h2>Current Features</h2><ul>
                                {features.map((feature) => <FeatureList key={feature._id} feature={feature} />)}
                            </ul>
                        </div>}
                    </div>
                }
            </div>}
        </div>
    );
};

export default PropFeatures;
