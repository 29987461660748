import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import PropDropdown from "../components/PropDropdown";
import PropList from '../components/PropList'
import { BsPlusCircleFill } from "react-icons/bs";
import configData from '../config.json';

import { useOwnersContext } from "../hooks/useOwnersContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { useFeatureContext } from "../hooks/useFeatureContext";
import AddFeature from "../components/AddPropFeature";


const AddProperty = () => {
  var newOwnersArray = [];
  var serviceArray = [];
  var newFeaturesArray = [];

  const [propOwnerEmail, setPropOwnerEmail] = useState("");
  const [propOwnerEmail2, setPropOwnerEmail2] = useState("");
  const [propFeatures, setPropFeatures] = useState([]);
  const [propServices, setPropServices] = useState([]);
  const [propAddress1, setPropAddress1] = useState("");
  const [propAddress2, setPropAddress2] = useState("");
  const [propCity, setPropCity] = useState("");
  const [propState, setPropState] = useState("");
  const [propZip, setPropZip] = useState("");
  const [propImage, setPropImage] = useState("");
  const [propManagerName, setPropManagerName] = useState("");
  const [propManagerPhone, setPropManagerPhone] = useState("");
  const [propManagerEmail, setPropManagerEmail] = useState("");
  const [propEmergencyName, setPropEmergencyName] = useState("");
  const [propEmergencyPhone, setPropEmergencyPhone] = useState("");
  const [propEmergencyEmail, setPropEmergencyEmail] = useState("");
  const [propFeatureInput, setPropFeatureInput] = useState('')
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const [propID, setPropID] = useState('')
  const [featurePopUp, setFeaturePopUp] = useState(false)
  // console.log(propServices)

  const { user } = useAuthContext();
  const { features } = useFeatureContext();

  const [isLoading, setIsLoading] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(propImage)

    if (!user) {
      setError("You must be logged in");
      return;
    }
    // ----------------------------------------------------------------------------------
    // const form = document.getElementById('propForm');
    const formData = new FormData()
    formData.append('propImage', propImage);
    console.log(propImage)
    // -----------------------------------------------------------------------------------

    formData.append('ownerEmail', propOwnerEmail);
    formData.append('ownerEmail2', propOwnerEmail2);
    formData.append('propAddress1', propAddress1);
    formData.append('propAddress2', propAddress2);
    formData.append('propCity', propCity);
    formData.append('propState', propState);
    formData.append('propZip', propZip);
    formData.append('propManName', propManagerName);
    formData.append('propManPhone', propManagerPhone);
    formData.append('propManEmail', propManagerEmail);
    formData.append('propEmerName', propEmergencyName);
    formData.append('propEmerPhone', propEmergencyPhone);
    formData.append('propEmerEmail', propEmergencyEmail);
    formData.append('propServices', JSON.stringify(propServices));
    formData.append('propFeatures', JSON.stringify(propFeatures));
    // propFeatures.forEach((feature) => {
    //   formData.append('propFeatures[]', feature);
    // })
    // console.log(property)
    const response = await fetch(`${configData.SERVER_URL}/api/properties`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      body: formData
    });
    console.log(formData)
    const json = await response.json();
    console.log(json)

    if (!response.ok) {
      setError(json.error);
      setEmptyFields(json.emptyFields);
    }
    if (response.ok) {
      setPropID(json._id)
      setPropOwnerEmail("");
      setPropOwnerEmail2('');
      setPropFeatures([]);
      setPropServices([]);
      setPropAddress1("");
      setPropAddress2("");
      setPropCity("");
      setPropState("");
      setPropZip("");
      setPropImage("");
      setPropManagerName("");
      setPropManagerPhone("");
      setPropManagerEmail("");
      setPropEmergencyName("");
      setPropEmergencyPhone("");
      setPropEmergencyEmail("");
      setError('');
      setEmptyFields([]);
      const fileField = document.getElementById('imageUpload');
      fileField.value = null;
    }
  }

  const handlePropFeaturesChange = (e) => {
    e.preventDefault();
    if (e.target.value) {
      let featureArr = e.target.value.split(",");
      let featureObj = { 'name': featureArr[0], 'id': featureArr[1] };
      setPropFeatures([featureObj, ...propFeatures]);
      console.log(propFeatures)
    }
  };

  const handlePropServicesChange = (event) => {
    if (event.target.value) {
      let serviceArr = event.target.value.split(",");
      let serviceObj = { 'name': serviceArr[0], 'id': serviceArr[1] };
      setPropServices([serviceObj, ...propServices]);
      console.log(propServices)
    }
  };
  const handlePropOwnerChange = (event) => {
    setPropOwnerEmail(event.target.value);
  };
  const handlePropOwnerChange2 = (event) => {
    setPropOwnerEmail2(event.target.value);
  };
  const handlePropImageUpload = (e) => {
    console.log(propImage)
    setPropImage(e.target.files[0])
  }

  const { services, owners, dispatch } = useOwnersContext();
  const { dispatch: featureDispatch } = useFeatureContext();

  useEffect(() => {
    setIsLoading(false)

    setError('')
    const fetchOptions = async () => {
      const OwnerResponse = await fetch(`${configData.SERVER_URL}/api/owners`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const jsonOwner = await OwnerResponse.json();

      if (OwnerResponse.ok) {
        dispatch({ type: "SET_OWNERS", payload: jsonOwner });
      }
      const response = await fetch(`${configData.SERVER_URL}/api/vendors`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();

      if (response.ok) {
        console.log(json)
        dispatch({ type: "SET_SERVICES", payload: json });
      }
      const featureResponse = await fetch(`${configData.SERVER_URL}/api/features`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const featureJson = await featureResponse.json();

      if (featureResponse.ok) {
        featureDispatch({ type: "SET_FEATURES", payload: featureJson });
      }
    };

    if (user) {
      fetchOptions();
    }

    setPropServices(propServices)
  }, [dispatch, user, featureDispatch, propServices]);

  if (owners) {
    owners.forEach((owner) => {
      newOwnersArray.push({ 'label': `${owner.ownerFName} ${owner.ownerLName}`, 'value': owner.ownerEmail })
    })
    newOwnersArray.sort((a, b) => a.label.localeCompare(b.label))
  }
  if (features) {
    features.forEach((feature) => {
      newFeaturesArray.push({ 'label': feature.featureName, 'value': feature.featureName })
    })
  }
  // if (services) {
  //   services.forEach((service) => {
  //     serviceArray.push({ 'label': service.serviceName, 'value': [`${service.serviceName}`, `${service._id}`], 'id': service._id })
  //   })
  // }
  if (services) {
    services.forEach((vendor) => {
      vendor.vendorServices.forEach((service) => {
        serviceArray.push({ 'label': `${service} - ${vendor.vendorName}`, 'value': [`${service} - ${vendor.vendorName}`, `${vendor._id}`], 'id': vendor._id })
      })
    })
    serviceArray.sort((a, b) => a.label.localeCompare(b.label))
  }

  return (
    <div>{!isLoading &&
      <div>
        {!user ? <Navigate to="/login" /> :
          <div>
            <h1>Add New Property</h1>
            <div className="add-property-wrapper">
              <div onSubmit={handleSubmit} enctype="multipart/form-data" id="propForm">
                <div className="new-property-grid">
                  <div className="new-property-grid-col">
                    <label className="new-prop-label-title feature" htmlFor="">Owner</label>
                    <PropDropdown

                      options={newOwnersArray}
                      value={propOwnerEmail}
                      onChange={handlePropOwnerChange}
                    />
                    <p>{propOwnerEmail}</p>
                    <label className="new-prop-label-title feature" htmlFor="">Second Owner</label>
                    <PropDropdown

                      options={newOwnersArray}
                      value={propOwnerEmail2}
                      onChange={handlePropOwnerChange2}
                    />
                    <p>{propOwnerEmail2}</p>
                    <label htmlFor="">Address 1</label>
                    <input type="text" onChange={(e) => setPropAddress1(e.target.value)} value={propAddress1} />
                    <label htmlFor="">Address 2</label>
                    <input type="text" onChange={(e) => setPropAddress2(e.target.value)} value={propAddress2} />
                    <label htmlFor="">City</label>
                    <input type="text" onChange={(e) => setPropCity(e.target.value)} value={propCity} />
                    <div className="new-prop-input-2col">
                      <div>
                        <label htmlFor="">State</label>
                        <input className="input-space" type="text" onChange={(e) => setPropState(e.target.value)} value={propState} />
                      </div>
                      <div>
                        <label htmlFor="">Zip</label>
                        <input type="text" onChange={(e) => setPropZip(e.target.value)} value={propZip} />
                      </div>
                    </div>
                  </div>
                  <div className="new-property-grid-col">
                    <label className="new-prop-label-title feature">Features</label>
                    <PropDropdown

                      options={newFeaturesArray}
                      value={propFeatures}
                      onChange={handlePropFeaturesChange}
                    />
                    <button onClick={(e) => { e.preventDefault(); setFeaturePopUp(true) }}>Add New Feature</button>
                    <PropList
                      optionSet={propFeatures}
                    />
                    {featurePopUp && <div className="featurePopUp"><button onClick={(e) => { e.preventDefault(); setFeaturePopUp(false) }}>Close Popup</button><AddFeature /></div>}
                  </div>
                  <div className="new-property-grid-col">
                    <label className="new-prop-label-title feature">Services</label>
                    <PropDropdown

                      options={serviceArray}
                      value={propServices}
                      onChange={handlePropServicesChange}
                    />
                    <PropList
                      optionSet={propServices}
                    />
                  </div>
                </div>
                <div className="new-property-grid row-2">
                  <div className="new-property-grid-col">
                    <label className="new-prop-label-title" htmlFor="">Upload Image</label>
                    <input
                      type="file"
                      onChange={handlePropImageUpload}
                      id='imageUpload'
                    />
                  </div>
                  <div className="new-property-grid-col">
                    <p className="new-prop-label-title">Manager</p>
                    <label htmlFor="">Name</label>
                    <input type="text" onChange={(e) => setPropManagerName(e.target.value)} value={propManagerName} />
                    <label htmlFor="">Phone</label>
                    <input type="text" onChange={(e) => setPropManagerPhone(e.target.value)} value={propManagerPhone} />
                    <label htmlFor="">Email</label>
                    <input type="text" onChange={(e) => setPropManagerEmail(e.target.value)} value={propManagerEmail} />
                  </div>
                  <div className="new-property-grid-col">
                    <p className="new-prop-label-title">Emergency</p>
                    <label htmlFor="">Name</label>
                    <input type="text" onChange={(e) => setPropEmergencyName(e.target.value)} value={propEmergencyName} />
                    <label htmlFor="">Phone</label>
                    <input type="text" onChange={(e) => setPropEmergencyPhone(e.target.value)} value={propEmergencyPhone} />
                    <label htmlFor="">Email</label>
                    <input type="text" onChange={(e) => setPropEmergencyEmail(e.target.value)} value={propEmergencyEmail} />
                  </div>
                </div>
                <div className="add-button-wrapper" onClick={handleSubmit}>
                  <div className="add-button">
                    <BsPlusCircleFill />
                  </div>
                  <p>Add Property</p>
                </div>
                <div>
                  <p>{error}</p>
                  {!propID ? (
                    null
                  ) : (
                    <div>
                      <p>Property successfully added!</p>
                      <Link className="button" to={`/properties/${propID}`}>View Property</Link>
                    </div>
                  )}
                  {/* <Link className="button" to={`/properties/${propID}`}>View Property</Link> */}
                  <p>{error}</p>
                  <ul>{emptyFields && emptyFields.map((field) => (<li>{field}</li>))}</ul>
                </div>
              </div>
            </div>
          </div >}
      </div >}
    </div>
  );
};

export default AddProperty;
