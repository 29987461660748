import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import configData from '../config.json';

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();

  const signup = async (email, password) => {
    setIsLoading(true);
    setError(null);

    const response = await fetch(`${configData.SERVER_URL}/api/user/signup`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
    });
    const json = await response.json();

    if (!response.ok) {
      setIsLoading(false);
      setSuccess(false);
      setError(json.error);
    }
    if (response.ok) {
      //save user to local storage
      // localStorage.setItem("user", JSON.stringify(json));

      //update the authContext
      // dispatch({ type: "LOGIN", payload: json });

      // update loading state
      setSuccess(true);
      setIsLoading(false);
    }
  };

  return { signup, isLoading, error, success };
};
