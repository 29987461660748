import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import configData from '../config.json';

import { useAuthContext } from "../hooks/useAuthContext";
import { useOwnersContext } from "../hooks/useOwnersContext";
import { useFeatureContext } from "../hooks/useFeatureContext";

// import propImage from "../assets/image-1.png";
import AddEventButton from "./AddEventButton";
import AddNoteButton from './AddNote';
import PropDropdown from "./PropDropdown";
import AddFeature from "./AddPropFeature";
import PropList from './PropList'
import Note from './Note';

function SingleProperty() {
  const redirect = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [property, setProperty] = useState({});
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [vendorIDList, setVendorIDList] = useState([]);
  const [editStatus, setEditStatus] = useState(false);
  var newOwnersArray = [];
  var serviceArray = [];
  var featureArray = [];
  const [propOwnerEmail, setPropOwnerEmail] = useState("");
  const [propSecondOwnerEmail, setPropSecondOwnerEmail] = useState("");
  const [propFeatures, setPropFeatures] = useState([]);
  const [propServices, setPropServices] = useState([]);
  const [propAddress1, setPropAddress1] = useState("");
  const [propAddress2, setPropAddress2] = useState("");
  const [propCity, setPropCity] = useState("");
  const [propState, setPropState] = useState("");
  const [propZip, setPropZip] = useState("");
  const [propImage, setPropImage] = useState("");
  const [propManagerName, setPropManagerName] = useState("");
  const [propManagerPhone, setPropManagerPhone] = useState("");
  const [propManagerEmail, setPropManagerEmail] = useState("");
  const [propEmergencyName, setPropEmergencyName] = useState("");
  const [propEmergencyPhone, setPropEmergencyPhone] = useState("");
  const [propEmergencyEmail, setPropEmergencyEmail] = useState("");
  const [oldPropImage, setOldPropImage] = useState('');
  const [imageUpdate, setImageUpdate] = useState('');
  const [propFeature, setPropFeature] = useState('');
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const [imgError, setImgError] = useState(false)
  const [featurePopUp, setFeaturePopUp] = useState(false)

  const handleDelete = async () => {
    if (!user) {
      return;
    }
    setOldPropImage(property.propImage)
    const response = await fetch(`${configData.SERVER_URL}/api/properties/` + property._id, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();

    if (response.ok) {
      dispatch({ type: "DELETE_PROPERTY", payload: json });
      setOldPropImage('')
      redirect('/properties');
    }
  };

  const cancelEdit = () => {
    setEditStatus(false)
    setPropOwnerEmail("");
    setPropSecondOwnerEmail('');
    setPropFeatures(property.propFeatures);
    setPropServices([]);
    setPropAddress1("");
    setPropAddress2("");
    setPropCity("");
    setPropState("");
    setPropZip("");
    setPropImage("");
    setPropManagerName("");
    setPropManagerPhone("");
    setPropManagerEmail("");
    setPropEmergencyName("");
    setPropEmergencyPhone("");
    setPropEmergencyEmail("");
    setError('');
    setPropFeature('')
    setEmptyFields([]);
    const fileField = document.getElementById('imageUpload');
    fileField.value = null;
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    // console.log(propImage)

    if (!user) {
      setError("You must be logged in");
      return;
    }
    const propChanges = { 'propFeatures': propFeatures, propServices }
    // ----------------------------------------------------------------------------------
    const formData = new FormData()
    // -----------------------------------------------------------------------------------
    if (imageUpdate === '') {
      if (propOwnerEmail !== '') {
        propChanges['ownerEmail'] = propOwnerEmail;
      }
      if (propSecondOwnerEmail !== '') {
        if (propSecondOwnerEmail === 'clear') {
          propChanges['ownerEmail2'] = '';
        } else {
          propChanges['ownerEmail2'] = propSecondOwnerEmail;
        }
      }
      if (propAddress1 !== '') {
        propChanges['propAddress1'] = propAddress1;
      }
      if (propAddress2 !== '') {
        propChanges['propAddress2'] = propAddress2;
      }
      if (propCity !== '') {
        propChanges['propCity'] = propCity;
      }
      if (propState !== '') {
        propChanges['propState'] = propState;
      }
      if (propZip !== '') {
        propChanges['propZip'] = propZip;
      }
      if (propManagerName !== '') {
        propChanges['propManName'] = propManagerName;
      }
      if (propManagerPhone !== '') {
        propChanges['propManPhone'] = propManagerPhone;
      }
      if (propManagerEmail !== '') {
        propChanges['propManEmail'] = propManagerEmail;
      }
      if (propEmergencyName !== '') {
        propChanges['propEmerName'] = propEmergencyName;
      }
      if (propEmergencyPhone !== '') {
        propChanges['propEmerPhone'] = propEmergencyPhone;
      }
      if (propEmergencyEmail !== '') {
        propChanges['propEmerEmail'] = propEmergencyEmail;
      }
      console.log(JSON.stringify(propChanges))

      var response = await fetch(`${configData.SERVER_URL}/api/properties/` + property._id, {
        method: 'PUT',
        body: JSON.stringify(propChanges),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        }
      })
    }
    // console.log({ imageUpdate, propChanges })
    if (imageUpdate !== '') {
      formData.append('propImage', propImage);
      if (propOwnerEmail !== '') {
        formData.append('ownerEmail', propOwnerEmail);
      }
      if (propSecondOwnerEmail !== '') {
        if (propSecondOwnerEmail === 'clear') {
          formData.append('ownerEmail2', '');
        } else {
          formData.append('ownerEmail2', propSecondOwnerEmail);
        }
      }
      if (propAddress1 !== '') {
        formData.append('propAddress1', propAddress1);
      }
      if (propAddress2 !== '') {
        formData.append('propAddress2', propAddress2);
      }
      if (propCity !== '') {
        formData.append('propCity', propCity);
      }
      if (propState !== '') {
        formData.append('propState', propState);
      }
      if (propZip !== '') {
        formData.append('propZip', propZip);
      }
      if (propManagerName !== '') {
        formData.append('propManName', propManagerName);
      }
      if (propManagerPhone !== '') {
        formData.append('propManPhone', propManagerPhone);
      }
      if (propManagerEmail !== '') {
        formData.append('propManEmail', propManagerEmail);
      }
      if (propEmergencyName !== '') {
        formData.append('propEmerName', propEmergencyName);
      }
      if (propEmergencyPhone !== '') {
        formData.append('propEmerPhone', propEmergencyPhone);
      }
      if (propEmergencyEmail !== '') {
        formData.append('propEmerEmail', propEmergencyEmail);
      }
      formData.append('propServices', JSON.stringify(propServices));
      propFeatures.forEach((feature) => {
        formData.append('propFeatures[]', feature);
      })
      var response = await fetch(`${configData.SERVER_URL}/api/properties/${property._id}/update-img`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        body: formData
      });
    }
    console.log({ imageUpdate, oldPropImage })
    // console.log(property)

    const json = await response.json();
    // console.log(json)

    if (!response.ok) {
      setError(json.error);
      setEmptyFields(json.emptyFields);
      // attempt to delete upload
    }
    if (response.ok) {
      setPropOwnerEmail("");
      setPropFeatures(property.propFeatures);
      setPropServices([]);
      setPropAddress1("");
      setPropAddress2("");
      setPropCity("");
      setPropState("");
      setPropZip("");
      setPropImage("");
      setPropManagerName("");
      setPropManagerPhone("");
      setPropManagerEmail("");
      setPropEmergencyName("");
      setPropEmergencyPhone("");
      setPropEmergencyEmail("");
      setOldPropImage("");
      setError('');
      setEmptyFields([]);
      setPropFeature('')
      setImgError(false)
      const fileField = document.getElementById('imageUpload');
      fileField.value = null;
      setEditStatus(false)
      redirect('/properties');
    }
  }

  const handlePropFeaturesChange = (event) => {
    console.log(event.target.value, propFeatures, property.propFeatures)
    if (event.target.value) {
      setPropFeatures([event.target.value, ...propFeatures]);
    }
  };

  const handlePropServicesChange = (event) => {
    if (event.target.value) {
      let serviceArr = event.target.value.split(",");
      let serviceObj = { 'name': serviceArr[0], 'id': serviceArr[1] };
      setPropServices([serviceObj, ...propServices]);
      console.log(propServices)
    }
  };
  const handlePropOwnerChange = (event) => {
    setPropOwnerEmail(event.target.value);
  };
  const handlePropSecondOwnerChange = (event) => {
    setPropSecondOwnerEmail(event.target.value);
  };
  const handlePropImageUpload = (e) => {
    console.log(propImage)
    setPropImage(e.target.files[0])
    setImageUpdate('update-img')
  }

  const { services, owners, dispatch } = useOwnersContext();
  const { features, dispatch: featureDispatch } = useFeatureContext();
  useEffect(() => {
    setIsLoading(false)

    setError('')
    const fetchOptions = async () => {
      const OwnerResponse = await fetch(`${configData.SERVER_URL}/api/owners`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const jsonOwner = await OwnerResponse.json();

      if (OwnerResponse.ok) {
        dispatch({ type: "SET_OWNERS", payload: jsonOwner });
      }
      const response = await fetch(`${configData.SERVER_URL}/api/vendors`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();

      if (response.ok) {
        dispatch({ type: "SET_SERVICES", payload: json });
      }
      const featureResponse = await fetch(`${configData.SERVER_URL}/api/features`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const featureJson = await featureResponse.json();

      if (featureResponse.ok) {
        featureDispatch({ type: "SET_FEATURES", payload: featureJson });
      }
    };
    console.log()

    if (user) {
      fetchOptions();
    }

    setPropServices(propServices)

    const fetchProperty = async () => {
      // console.log(user);
      // console.log(localStorage.getItem("user"));

      let localUser = JSON.parse(localStorage.getItem("user"));
      const res = await axios.get(`${configData.SERVER_URL}/api/properties/` + path, {
        headers: {
          Authorization: `Bearer ${localUser.token}`,
        },
      });
      setProperty(res.data);
      // let serviceArray = []
      // res.data.propServices.forEach(s => serviceArray.push)
      setPropServices(res.data.propServices)
      setPropFeatures(res.data.propFeatures)
      if (property.propImage) {
        setOldPropImage(property.propImage);
      }
      console.log(res.data);
    };
    const fetchVendors = async () => {

      let localUser = JSON.parse(localStorage.getItem("user"));
      const res = await axios.get(`${configData.SERVER_URL}/api/vendors/`, {
        headers: {
          Authorization: `Bearer ${localUser.token}`,
        },
      });
      // console.log(res.data)
      let vendorIDs = []
      res.data.forEach(e => vendorIDs.push(e._id))
      setVendorIDList(vendorIDs);
      // console.log(path);
    };
    fetchProperty()
    fetchVendors()
  }, [path, dispatch, user, featureDispatch]);

  if (owners) {
    owners.forEach((owner) => {
      newOwnersArray.push({ 'label': `${owner.ownerFName} ${owner.ownerLName}`, 'value': owner.ownerEmail })
    })
  }
  if (services) {
    services.forEach((vendor) => {
      vendor.vendorServices.forEach((service) => {
        serviceArray.push({ 'label': `${service} - ${vendor.vendorName}`, 'value': [`${service} - ${vendor.vendorName}`, `${vendor._id}`], 'id': vendor._id })
      })
    })
  }
  if (features) {
    features.forEach((feature) => {
      featureArray.push({ 'label': feature.featureName, 'value': feature.featureName })
    })
  }
  // Future Events
  var upcomingEvents = [];
  if (property.propEvents) {
    const now = Date.now();
    const futureDates = property.propEvents.filter(date => {
      // Filter out dates in the past or falsey values
      return date && (new Date(date.start)).getTime() > now;
    });
    // console.log(futureDates.length);
    upcomingEvents = futureDates.slice(0, 11);
  }
  // Past Events
  var pastEvents = [];
  if (property.propEvents) {
    const now = Date.now();
    const pastDates = property.propEvents.filter(date => {
      // Filter out dates in the past or falsey values
      return date && (new Date(date.start)).getTime() < now;
    });
    // console.log(pastDates.length);
    pastEvents = pastDates;
  }
  function searchPast() {
    // Declare variables
    var input, filter, ul, li, p, i, txtValue;
    input = document.getElementById('myInput');
    filter = input.value.toUpperCase();
    ul = document.getElementById("myUL");
    li = ul.getElementsByTagName('li');
    console.log(filter === '')

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      p = li[i].getElementsByTagName("p")[0];
      txtValue = p.textContent || p.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "block";
      } else {
        li[i].style.display = "none";
      }
      if (filter === '') {
        li[i].style.display = "none";
      }
    }
  }
  // console.log(property)
  return (
    <div>{!isLoading &&
      <div>
        {!user ? <Navigate to="/login" /> :
          <div className="property-wrapper">
            <div className="property-col-left">
              {!editStatus ? (
                <div>
                  <h4>{property.propAddress1}{property.propAddress2 && `, ${property.propAddress2}`}</h4>
                  <p>{`${property.propCity}, ${property.propState} ${property.propZip}`}</p>
                  <div className="property-button-row">
                    <button onClick={() => setEditStatus(true)}>Edit</button>
                    <button onClick={handleDelete}>Delete</button>
                  </div>
                </div>
              ) : (
                <div>
                  <input type="text" placeholder={property.propAddress1} onChange={(e) => setPropAddress1(e.target.value)} value={propAddress1} />
                  <input type="text" placeholder={property.propAddress2} onChange={(e) => setPropAddress2(e.target.value)} value={propAddress2} />
                  <div className="new-prop-input-2col">
                    <div>
                      <input type="text" placeholder={property.propCity} onChange={(e) => setPropCity(e.target.value)} value={propCity} />
                    </div>
                    <div>
                      <input placeholder={property.propState} className="input-space" type="text" onChange={(e) => setPropState(e.target.value)} value={propState} />
                    </div>
                    <div>
                      <input placeholder={property.propZip} type="text" onChange={(e) => setPropZip(e.target.value)} value={propZip} />
                    </div>
                  </div>
                  <div className="property-button-row">
                    <button onClick={cancelEdit}>Cancel Edit</button>
                    <button onClick={handleUpdate}>Submit Changes</button>
                  </div>
                </div>
              )}
              {!editStatus ? (
                <div className="property-features">
                  <h5>Features</h5>
                  <div className="property-features-grid">
                    {property.propFeatures && property.propFeatures.map((feature) => <p>{feature.name}</p>)}
                  </div>
                </div>
              ) : (
                <div className="client-item-col-left client-property-layout">
                  <h5>Features</h5>
                  <div>
                    <PropDropdown
                      options={featureArray}
                      value={propFeatures}
                      onChange={handlePropFeaturesChange}
                    />
                    {featurePopUp ? <div className="featurePopUp"><button onClick={(e) => { e.preventDefault(); setFeaturePopUp(false) }}>Close Popup</button><AddFeature /></div> : <button onClick={(e) => { e.preventDefault(); setFeaturePopUp(true) }}>Add New Feature</button>}
                  </div>
                  <PropList
                    optionSet={propFeatures}
                  />
                </div>
              )}
              {!editStatus ? (
                <div className="property-features">
                  <h5>Services</h5>
                  <ul className="property-features-grid">
                    {property.propServices && property.propServices.map((service) =>
                      <li>
                        {vendorIDList && <div>{vendorIDList.includes(service.id) ? (
                          <Link to={`/vendors/${service.id}`} style={{
                            color: 'black'
                          }}>{service.name}</Link>
                        ) : (
                          <p>Vendor is no longer available</p>
                        )}</div>}
                      </li>
                    )}
                  </ul>
                </div>
              ) : (
                <div className="new-property-grid-col">
                  <label className="new-prop-label-title feature">Services</label>
                  <PropDropdown

                    options={serviceArray}
                    value={propServices}
                    onChange={handlePropServicesChange}
                  />
                  <PropList
                    optionSet={propServices}
                  />
                </div>
              )}
              {/* <div className="property-features">
                <h5>Services</h5>
                <ul className="property-features-grid">
                  {property.propServices && property.propServices.map((service) =>
                    <li>
                      {vendorIDList && <div>{vendorIDList.includes(service.id) ? (
                        <Link to={`/vendors/${service.id}`} style={{
                          color: 'black'
                        }}>{service.name}</Link>
                      ) : (
                        <p>Vendor is no longer available</p>
                      )}</div>}
                    </li>
                  )}
                </ul>
              </div> */}
              <div>
                <div className="property-features property-events">
                  <h5>Upcoming Events</h5>
                  <AddEventButton address={property.propAddress1} />
                </div>
                {property.propEvents && <div>
                  {upcomingEvents[0] ? upcomingEvents.map((event) => <p>{event.start}: {event.title}</p>)
                    : <p>No Upcoming Events are currently scheduled</p>}
                </div>
                }
                {/* <p>Sept 23, 2022: Pool Cleaning</p> */}
              </div>
              <div>
                <div className="property-features property-events">
                  <h5>Past Events</h5>
                  <input type="text" id="myInput" onKeyUp={e => searchPast()} placeholder="Search Past Events" />
                </div>
                {property.propEvents && <ul id="myUL">
                  {pastEvents[0] ? pastEvents.map((event) => <li><p>{event.start}: {event.title}</p></li>)
                    : <p>No Past Events on record</p>}
                </ul>
                }
                {/* <p>Sept 23, 2022: Pool Cleaning</p> */}
              </div>
              <div className="client-item-col-left client-property-layout">

                <h4>Administrative Notes</h4>
                <AddNoteButton noteTarget={['Property Note', property._id, property.adminNotes]} />
                {property.notes ? property.notes.map((notes) =>
                  <Note notes={['Property Notes', notes]} />)
                  // <div className="notes">
                  //   <p>Submitted on: {note.submittedOn}</p>
                  //   <div dangerouslySetInnerHTML={{ __html: note.note }} />
                  // </div>)
                  : null}
              </div>
            </div>
            {!editStatus ? (
              <div className="property-col-right">
                {property.propImage ?
                  <img className="property-image" src={`${configData.SERVER_URL}/uploads/${property.propImage}`} alt="" onError={() => setImgError(true)} />
                  :
                  <img className="property-image" src={require(`../assets/garabedian-color-notagline-vertical-01.jpg`)} alt="" />
                }
                {imgError && <img className="property-image" src={require(`../assets/garabedian-color-notagline-vertical-01.jpg`)} alt="" />}
                <div className="owner-features">
                  {property.propOwner ? (
                    <div>
                      {property.propOwner2[0] ? (
                        <h5>Owners</h5>
                      ) : (
                        <h5>Owner</h5>
                      )}
                    </div>) : (
                    <h5>Owner</h5>)}
                  {/* <h5>Owner</h5> */}
                  <div>
                    {property.propOwner ?
                      (<div>
                        {property.propOwner.length == 1 ? (
                          <div>
                            <div className="contactInfo">
                              <Link to={`/owners/${property.propOwner[0]._id}`} style={{

                                color: 'black'
                              }}>
                                <p><span style={{ fontWeight: "bold" }}>{`${property.propOwner[0].ownerFName} ${property.propOwner[0].ownerLName}`}</span></p>
                              </Link>
                              <p>{property.propOwner && `${property.propOwner[0].ownerAddress1}`}</p>
                              <p>{property.propOwner && `${property.propOwner[0].ownerCity}, ${property.propOwner[0].ownerState} ${property.propOwner[0].ownerZip}`}</p>
                              <p>{property.propOwner && `${property.propOwner[0].ownerPhone1}`}</p>
                              <p>{property.propOwner && `${property.propOwner[0].ownerEmail}`}</p>
                            </div>
                            {property.propOwner2 ? (
                              <div>
                                {property.propOwner2.length == 1 ? (
                                  <div className="contactInfo">
                                    <Link to={`/owners/${property.propOwner2[0]._id}`} style={{

                                      color: 'black'
                                    }}>
                                      <p><span style={{ fontWeight: "bold" }}>{`${property.propOwner2[0].ownerFName} ${property.propOwner2[0].ownerLName}`}</span></p>
                                    </Link>
                                    <p>{property.propOwner2 && `${property.propOwner2[0].ownerAddress1}`}</p>
                                    <p>{property.propOwner2 && `${property.propOwner2[0].ownerCity}, ${property.propOwner[0].ownerState} ${property.propOwner2[0].ownerZip}`}</p>
                                    <p>{property.propOwner2 && `${property.propOwner2[0].ownerPhone1}`}</p>
                                    <p>{property.propOwner2 && `${property.propOwner2[0].ownerEmail}`}</p>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        ) : <p> No owner assigned to this property </p>
                        }
                      </div>)
                      : <div>{property.propOwner2 ? (
                        <div>
                          {property.propOwner2.length == 1 ? (
                            <div className="contactInfo">
                              <Link to={`/owners/${property.propOwner2[0]._id}`} style={{

                                color: 'black'
                              }}>
                                <p><span style={{ fontWeight: "bold" }}>{`${property.propOwner2[0].ownerFName} ${property.propOwner2[0].ownerLName}`}</span></p>
                              </Link>
                              <p>{property.propOwner2 && `${property.propOwner2[0].ownerAddress1}`}</p>
                              <p>{property.propOwner2 && `${property.propOwner2[0].ownerCity}, ${property.propOwner[0].ownerState} ${property.propOwner2[0].ownerZip}`}</p>
                              <p>{property.propOwner2 && `${property.propOwner2[0].ownerPhone1}`}</p>
                              <p>{property.propOwner2 && `${property.propOwner2[0].ownerEmail}`}</p>
                            </div>
                          ) : null}
                        </div>
                      ) : <p> No owners assigned to this property </p>}</div>}
                  </div>
                </div>
                {property.propManName &&
                  <div className="owner-features">
                    <h5>Manager</h5>
                    <div className="contactInfo">
                      <p>
                        <span style={{ fontWeight: "bold" }}>{property.propManName}</span>
                      </p>
                      <p>{property.propManPhone}</p>
                      <p>{property.propManEmail}</p>
                    </div>
                  </div>
                }
                {property.propEmerName &&
                  <div className="owner-features">
                    <h5>Emergency</h5>
                    <div className="contactInfo">
                      <p>
                        <span style={{ fontWeight: "bold" }}>{property.propEmerName}</span>
                      </p>
                      <p>{property.propEmerPhone}</p>
                      <p>{property.propEmerEmail}</p>
                    </div>
                  </div>
                }
              </div>
            ) : (
              <div className='property-col-right'>
                {/* <p>Image Upload</p> */}
                <div className="new-property-grid-col">
                  <label className="new-prop-label-title" htmlFor="">Upload Image</label>
                  <input
                    type="file"
                    onChange={handlePropImageUpload}
                    id='imageUpload'
                  />
                </div>
                {/* <p>Owner Update</p> */}
                <div className="new-property-grid-col">
                  <label className="new-prop-label-title feature" htmlFor="">Owner</label>
                  <PropDropdown

                    options={newOwnersArray}
                    value={propOwnerEmail}
                    onChange={handlePropOwnerChange}
                  />
                </div>
                <div className="new-property-grid-col">
                  <label className="new-prop-label-title feature" htmlFor="">Second Owner</label>
                  <PropDropdown
                    identifier='Second Owner'
                    options={newOwnersArray}
                    value={propSecondOwnerEmail}
                    onChange={handlePropSecondOwnerChange}
                  />
                </div>
                {/* <p>Manager Update</p> */}
                <div className="new-property-grid-col">
                  <p className="new-prop-label-title">Manager</p>
                  <label htmlFor="">Name</label>
                  <input type="text" onChange={(e) => setPropManagerName(e.target.value)} value={propManagerName} />
                  <label htmlFor="">Phone</label>
                  <input type="text" onChange={(e) => setPropManagerPhone(e.target.value)} value={propManagerPhone} />
                  <label htmlFor="">Email</label>
                  <input type="text" onChange={(e) => setPropManagerEmail(e.target.value)} value={propManagerEmail} />
                </div>
                {/* <p>Emergency Update</p> */}
                <div className="new-property-grid-col">
                  <p className="new-prop-label-title">Emergency</p>
                  <label htmlFor="">Name</label>
                  <input type="text" onChange={(e) => setPropEmergencyName(e.target.value)} value={propEmergencyName} />
                  <label htmlFor="">Phone</label>
                  <input type="text" onChange={(e) => setPropEmergencyPhone(e.target.value)} value={propEmergencyPhone} />
                  <label htmlFor="">Email</label>
                  <input type="text" onChange={(e) => setPropEmergencyEmail(e.target.value)} value={propEmergencyEmail} />
                </div>
              </div>
            )}
          </div>}
      </div>}
    </div>
  );
}

export default SingleProperty;
