import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { OwnersContextProvider } from "./context/OwnersContext";
import { AuthContextProvider } from "./context/AuthContext";
import { PropertiesContextProvider } from "./context/PropertiesContext";
import { CalendarContextProvider } from "./context/CalendarContext";
import { ServiceContextProvider } from './context/ServiceContext';
import { VendorsContextProvider } from './context/VendorsContext';
import { FeatureContextProvider } from './context/FeatureContext';

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <CalendarContextProvider>
        <OwnersContextProvider>
          <PropertiesContextProvider>
            <ServiceContextProvider>
              <VendorsContextProvider>
                <FeatureContextProvider>
                  <App />
                </FeatureContextProvider>
              </VendorsContextProvider>
            </ServiceContextProvider>
          </PropertiesContextProvider>
        </OwnersContextProvider>
      </CalendarContextProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
