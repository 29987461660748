import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { BsPlusCircleFill } from "react-icons/bs";
import configData from '../config.json';

// Hooks
import { useAuthContext } from "../hooks/useAuthContext";
import { useFeatureContext } from "../hooks/useFeatureContext";

const AddFeature = () => {
    const [featureName, setFeatureName] = useState("");
    const [error, setError] = useState(null);
    const [emptyFields, setEmptyFields] = useState([]);
    const [success, setSuccess] = useState(false)

    const { user } = useAuthContext();
    const { dispatch } = useFeatureContext();
    const [isLoading, setIsLoading] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user) {
            setError("You must be logged in");
            return;
        }

        let feature = { featureName }
        const response = await fetch(`${configData.SERVER_URL}/api/features`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(feature)
        });
        const json = await response.json();

        if (!response.ok) {
            setError(json.error);
            setEmptyFields(json.emptyFields);
            setSuccess(false);
        }
        if (response.ok) {
            setFeatureName("");
            setError('');
            setEmptyFields([]);
            setSuccess(true);
            dispatch({ type: "CREATE_FEATURE", payload: json });
        }
    }

    useEffect(() => {
        setIsLoading(false)
    }, [dispatch, user]);

    return (
        <div>{!isLoading &&
            <div>
                {!user ? <Navigate to="/login" /> :
                    <div >
                        <form onSubmit={handleSubmit} id="featureForm">
                            <h2>Add New Feature</h2>
                            <div>
                                <label className="new-prop-label-title" htmlFor="">
                                    Feature Name
                                </label>
                                <input type="text" onChange={(e) => setFeatureName(e.target.value)} value={featureName} />
                            </div>
                            <div className="add-button" onClick={handleSubmit}>
                                <BsPlusCircleFill />
                            </div>
                            <div>
                                <p>{error}</p>
                                {success && (
                                    <p>Feature successfully added!</p>
                                )}
                                <ul>{emptyFields && emptyFields.map((field) => (<li>{field}</li>))}</ul>
                            </div>
                        </form>
                    </div>}
            </div>}
        </div>
    );
};

export default AddFeature;