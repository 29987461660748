import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

import gbLogo from "../assets/garabedian-color-notagline-horizonal.png";

const Navbar = () => {
  const { logout } = useLogout();
  const { user } = useAuthContext();

  const handleClick = () => {
    logout();
  };

  return (
    <header>
      <div className="container">
        <Link to="/">
          <img src={gbLogo} alt="Garabedian Properties Logo" className="logo" />
        </Link>
        <nav className="nav-contain">
          {user && (
            <>
              <div className="nav-menu-container">
                <div>
                  <Link to="/properties">Properties</Link>
                </div>
                <div>
                  <Link to="/vendors">Vendors</Link>
                </div>
                <div>
                  <Link to="/clients">Owners</Link>
                </div>
                <div>
                  <Link to="/calendar">Schedule</Link>
                </div>
              </div>
              <div>
                <span>{user.email}</span>
                <button onClick={handleClick}>Logout</button>
              </div>
            </>
          )}
          {/* {!user && (
            <div>
              <Link to="/login">Login</Link>
            </div>
          )} */}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
