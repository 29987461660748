import { Link } from "react-router-dom";
import { useOwnersContext } from "../hooks/useOwnersContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { BsTrash } from "react-icons/bs";
import configData from '../config.json';

//date fns
//import formatDistanceToNow from "date-fns/formatDistanceToNow";

const ClientList = ({ owner }) => {
  const { dispatch } = useOwnersContext();
  const { user } = useAuthContext();

  const handleClick = async () => {
    if (!user) {
      return;
    }
    const response = await fetch(`${configData.SERVER_URL}/api/owners/` + owner._id, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();

    if (response.ok) {
      dispatch({ type: "DELETE_OWNER", payload: json });
    }
  };

  return (
    <div className="owner-details clients-in-grid">
      <div className="client-item-layout">
        <div className="client-col-left">
          <div>
            <h4>
              {owner.ownerLName}, {owner.ownerFName}
            </h4>
            <p>
              <strong>Address: </strong>
            </p>
            <p>{owner.ownerAddress1}</p>
            <p>{owner.ownerAddress2}</p>
            <p>
              {owner.ownerCity}, {owner.ownerState} {owner.ownerZip}
            </p>
          </div>
          <div className="client-item-col-right">
            <p>
              <strong>Contact Info: </strong>
            </p>
            <p>{owner.ownerPhone1}</p>
            <p>{owner.ownerPhone2 && owner.ownerPhone2}</p>
            <p>{owner.ownerEmail}</p>
          </div>
        </div>

        <div className="client-col-right">
          <div className="client-item-col-far-right">
            {/* <span className="icon" onClick={handleClick}>
              <BsTrash className="trash-icon" />
            </span> */}
            <span>
              <Link to={`/clients/${owner._id}`}>
                <button className="">View</button>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientList;
