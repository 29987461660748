import { createContext, useReducer } from "react";

export const FeatureContext = createContext();

export const featuresReducer = (state, action) => {
    switch (action.type) {
        case "SET_FEATURES":
            return {
                features: action.payload,
            };
        case "CREATE_FEATURE":
            return {
                features: [action.payload, ...state.features],
            };
        case "DELETE_FEATURE":
            return {
                features: state.features.filter((p) => p._id !== action.payload._id),
            };
        default:
            return state;
    }
};

export const FeatureContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(featuresReducer, {
        features: null,
    });

    return (
        <FeatureContext.Provider value={{ ...state, dispatch }}>
            {children}
        </FeatureContext.Provider>
    );
};
