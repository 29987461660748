import { Link } from "react-router-dom";
import { useVendorsContext } from "../hooks/useVendorsContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { BsTrash } from "react-icons/bs";
import configData from '../config.json';

//date fns
//import formatDistanceToNow from "date-fns/formatDistanceToNow";

const ClientList = ({ vendor }) => {
    const { dispatch } = useVendorsContext();
    const { user } = useAuthContext();

    const handleClick = async () => {
        if (!user) {
            return;
        }
        const response = await fetch(`${configData.SERVER_URL}/api/vendors/` + vendor._id, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        });
        const json = await response.json();

        if (response.ok) {
            dispatch({ type: "DELETE_VENDOR", payload: json });
        }
    };

    return (
        <div className="owner-details  ">
            <div className="contact-info-block">
                <h4>
                    {vendor.vendorName}
                </h4>
                <div>
                    <p>{vendor.vendorAddress1}</p>
                    <p>{vendor.vendorAddress2}</p>
                    <p>
                        {vendor.vendorCity}, {vendor.vendorState} {vendor.vendorZip}
                    </p>
                    <p>{vendor.vendorPhone1}</p>
                </div>
                <div className="vendor-link-wrapper">
                    <span>
                        <Link to={`/vendors/${vendor._id}`}>
                            <button className="">View</button>
                        </Link>
                    </span>
                    <span className="icon" onClick={handleClick}>
                        <BsTrash className="trash-icon" />
                    </span>
                </div>
            </div>

        </div>
    );
};

export default ClientList;
