import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import configData from '../config.json';

import AddService from "../components/AddService";
import ServiceList from '../components/ServiceList';

// Hooks
import { useAuthContext } from "../hooks/useAuthContext";
import { useServiceContext } from "../hooks/useServiceContext";

const Services = () => {
    const { user } = useAuthContext();
    const { services, dispatch } = useServiceContext();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false)

        const fetchServices = async () => {
            const response = await fetch(`${configData.SERVER_URL}/api/services`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            const json = await response.json();

            if (response.ok) {
                dispatch({ type: "SET_SERVICES", payload: json });
            }
        };

        if (user) {
            fetchServices();
        }
    }, [dispatch, user]);

    return (
        <div>{!isLoading &&
            <div className="owner-details">
                {!user ? <Navigate to="/login" /> :
                    <div className="feature-section-wrapper">
                        <AddService />
                        {services && <div className="current-feature-wrapper">
                            <h2>Current Services</h2><ul>
                                {services.map((service) => <ServiceList key={service._id} service={service} />)}
                            </ul>
                        </div>}
                    </div>
                }
            </div>}
        </div>
    );
};

export default Services;
