import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { format } from 'date-fns'
import { BsPlusCircleFill } from "react-icons/bs";
import configData from '../config.json';

import { convertToRaw, EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//hooks
import { useAuthContext } from "../hooks/useAuthContext";
import { usePropertiesContext } from "../hooks/usePropertiesContext";
import { useOwnersContext } from "../hooks/useOwnersContext";

const Note = (notes) => {
    console.log(notes.notes[0]);
    const redirect = useNavigate();

    const { user } = useAuthContext();
    const { owners } = useOwnersContext();
    const { properties } = usePropertiesContext();
    const location = useLocation();
    const path = location.pathname.split("/")[2];

    const [popup, setPopup] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [status, setStatus] = useState("Submit");
    const [owner, setOwner] = useState({});
    const [error, setError] = useState(null);
    const [richText, setRichText] = useState()
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const handleDelete = async () => {
        if (!user) {
            return;
        }
        const response = await fetch(`${configData.SERVER_URL}/api/adminNotes/` + notes.notes[1]._id, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        });
        const json = await response.json();

        if (response.ok) {
            window.location.reload(false);
        }
    };

    const handleSubmit = async (e) => {
        // console.log(user);
        let notesArray = notes.notes[1]
        console.log(richText)

        // console.log(richText.blocks)
        const lastModifiedOn = format(new Date(), "MMM dd, yyyy' 'h:mm a");

        const editedNote = { 'note': richText, lastModifiedOn }

        const response = await fetch(`${configData.SERVER_URL}/api/adminNotes/` + notes.notes[1]._id, {
            method: "PUT",
            body: JSON.stringify(editedNote),
            headers: {
                Authorization: `Bearer ${user.token}`,
                "Content-Type": "application/json",
            },
        });
        const json = await response.json();

        if (!response.ok) {
            setError(json.error);
            console.log(json.error);
        }
        if (response.ok) {
            setRichText('');
            setError(null);
            setEditorState(() => EditorState.createEmpty())
        }

    }

    useEffect(() => {
        setEditorState(EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(notes.notes[1].note)
            )))
    }, [path]);

    return (
        <div>
            {!editStatus ? (
                <div className="notes">
                    <p>Submitted on: {notes.notes[1].submittedOn}</p>
                    {/* {notes.notes[1].lastModifiedOn && <p>Last modified on: {notes.notes[1].lastModifiedOn}</p>} */}
                    <div dangerouslySetInnerHTML={{ __html: notes.notes[1].note }} />
                    <button onClick={() => setEditStatus(true)}>Edit Note</button>
                    <button onClick={handleDelete}>Delete Note</button>
                </div>
            ) : (
                <form className="notes">
                    <Editor
                        // editorState={editorState} onChange={setEditorState}
                        placeholder={notes.notes[1].note}
                        // defaultEditorState={notes.notes[1].note}
                        editorState={editorState}
                        // editorState={EditorState.createWithContent(
                        //     ContentState.createFromBlockArray(
                        //         convertFromHTML(notes.notes[1].note)
                        //     )
                        // )}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={newState => {
                            setEditorState(newState);
                            setRichText(draftToHtml(convertToRaw(newState.getCurrentContent())))
                            // setRichText(convertToRaw(newState.getCurrentContent()))
                            // setRichText(newState.getCurrentContent())
                        }}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'embedded', 'emoji', 'image'],
                            // inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                        }}
                    // onChange={setEditorState}
                    />
                    <div className='popup-button-group'>
                        <button onClick={handleSubmit}>{status}</button>
                        <button onClick={() => { setRichText(''); setEditStatus(false); setEditorState(() => EditorState.createEmpty()) }}>Cancel Edit</button>
                    </div>
                </form>
            )}
        </div>



        // <div className="note-wrapper">
        //     {!popup ?
        //         <BsPlusCircleFill className='add-button' onClick={() => setPopup(true)}>

        //         </BsPlusCircleFill>
        //         :
        //         null}
        //     {popup ? (
        //         <form className="notes">
        //             <Editor
        //                 // editorState={editorState} onChange={setEditorState}
        //                 editorState={editorState}
        //                 toolbarClassName="toolbarClassName"
        //                 wrapperClassName="wrapperClassName"
        //                 editorClassName="editorClassName"
        //                 onEditorStateChange={newState => {
        //                     setEditorState(newState);
        //                     setRichText(draftToHtml(convertToRaw(newState.getCurrentContent())))
        //                     // setRichText(convertToRaw(newState.getCurrentContent()))
        //                     // setRichText(newState.getCurrentContent())
        //                 }}
        //                 toolbar={{
        //                     options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'embedded', 'emoji', 'image'],
        //                     // inline: { inDropdown: true },
        //                     list: { inDropdown: true },
        //                     textAlign: { inDropdown: true },
        //                     link: { inDropdown: true },
        //                     history: { inDropdown: true },
        //                 }}
        //             // onChange={setEditorState}
        //             />
        //             <div className='popup-button-group'>
        //                 <button onClick={handleSubmit}>{status}</button>
        //                 <button onClick={() => { setRichText(''); setPopup(false); setEditorState(() => EditorState.createEmpty()) }}>Close Popup</button>
        //             </div>
        //         </form>
        //     ) : null}
        //     {richText &&
        //         <div>
        //             <h3>Note Preview</h3>
        //             <div className="notes">
        //                 <p>Submitted on: {format(new Date(), "MMM dd, yyyy' 'h:mm a")}</p>
        //                 <div dangerouslySetInnerHTML={{ __html: richText }} />
        //             </div>
        //         </div>}
        // </div>
    );
};

export default Note;
