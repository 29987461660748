import { useAuthContext } from "./useAuthContext";
import { useOwnersContext } from "./useOwnersContext";

export const useLogout = () => {
  const { dispatch } = useAuthContext();
  const { dispatch: ownersDispatch } = useOwnersContext();

  const logout = () => {
    //remove user from storage
    localStorage.removeItem("user");

    //dispatch logout action
    dispatch({ type: "LOGOUT" });
    ownersDispatch({ type: "SET_OWNERS", payload: null });
  };

  return { logout };
};
