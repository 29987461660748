import React, { useState, useEffect } from "react";
import configData from '../config.json';
import { useLocation } from "react-router";
import axios from "axios";
import { format } from 'date-fns'
import { BsPlusCircleFill } from "react-icons/bs";

import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//hooks
import { useAuthContext } from "../hooks/useAuthContext";
import { usePropertiesContext } from "../hooks/usePropertiesContext";
import { useOwnersContext } from "../hooks/useOwnersContext";

const AddNoteButton = (noteTarget) => {
    // console.log(noteTarget);

    const { user } = useAuthContext();
    const { owners } = useOwnersContext();
    const { properties } = usePropertiesContext();
    const location = useLocation();
    const path = location.pathname.split("/")[2];

    const [popup, setPopup] = useState(false);
    const [status, setStatus] = useState("Submit");
    const [owner, setOwner] = useState({});
    const [error, setError] = useState(null);
    const [richText, setRichText] = useState()
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const handleSubmit = async (e) => {
        let notes = noteTarget.noteTarget[2]

        const submittedOn = format(new Date(), "MMM dd, yyyy' 'h:mm a");
        const note = { 'note': richText, submittedOn, 'noteParent': noteTarget.noteTarget[1] }

        if (noteTarget.noteTarget[0] === 'Client Note') {

            const response = await fetch(`${configData.SERVER_URL}/api/owners/adminNote/`, {
                method: "POST",
                body: JSON.stringify(note),
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    "Content-Type": "application/json",
                },
            });
            const json = await response.json();

            if (!response.ok) {
                setError(json.error);
                console.log(json.error);
            }
            if (response.ok) {
                setRichText('');
                setPopup(false);
                setError(null);
                setEditorState(() => EditorState.createEmpty())
            }

        } else if (noteTarget.noteTarget[0] === 'Property Note') {

            const response = await fetch(`${configData.SERVER_URL}/api/properties/adminNote/`, {
                method: "POST",
                body: JSON.stringify(note),
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    "Content-Type": "application/json",
                },
            });
            const json = await response.json();

            if (!response.ok) {
                setError(json.error);
                console.log(json.error);
            }
            if (response.ok) {
                setRichText('');
                setPopup(false);
                setError(null);
                setEditorState(() => EditorState.createEmpty())
            }
        } else if (noteTarget.noteTarget[0] === 'Vendor Note') {

            const response = await fetch(`${configData.SERVER_URL}/api/vendors//adminNote/`, {
                method: "POST",
                body: JSON.stringify(note),
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    "Content-Type": "application/json",
                },
            });
            const json = await response.json();

            if (!response.ok) {
                setError(json.error);
                console.log(json.error);
            }
            if (response.ok) {
                setRichText('');
                setPopup(false);
                setError(null);
                setEditorState(() => EditorState.createEmpty())
            }
        }
    }

    // useEffect(() => {

    //     const fetchOwner = async () => {
    //         // console.log(user);
    //         // console.log(localStorage.getItem("user"));

    //         let localUser = JSON.parse(localStorage.getItem("user"));
    //         const res = await axios.get("/api/owners/" + path, {
    //             headers: {
    //                 Authorization: `Bearer ${localUser.token}`,
    //             },
    //         });
    //         setOwner(res.data);
    //         console.log(path);
    //     };
    //     fetchOwner();
    // }, [path]);

    return (
        <div className="note-wrapper">
            {!popup ?
                <BsPlusCircleFill className='add-button' onClick={() => setPopup(true)}>

                </BsPlusCircleFill>
                :
                null}
            {popup ? (
                <form className="notes">
                    <Editor
                        // editorState={editorState} onChange={setEditorState}
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={newState => {
                            setEditorState(newState);
                            setRichText(draftToHtml(convertToRaw(newState.getCurrentContent())))
                            // setRichText(convertToRaw(newState.getCurrentContent()))
                            // setRichText(newState.getCurrentContent())
                        }}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'embedded', 'emoji', 'image'],
                            // inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                        }}
                    // onChange={setEditorState}
                    />
                    <div className='popup-button-group'>
                        <button onClick={handleSubmit}>{status}</button>
                        <button onClick={() => { setRichText(''); setPopup(false); setEditorState(() => EditorState.createEmpty()) }}>Discard</button>
                    </div>
                </form>
            ) : null}
            {richText &&
                <div>
                    <h3>Note Preview</h3>
                    <div className="notes">
                        <p>Submitted on: {format(new Date(), "MMM dd, yyyy' 'h:mm a")}</p>
                        <div dangerouslySetInnerHTML={{ __html: richText }} />
                    </div>
                </div>}
        </div>
    );
};

export default AddNoteButton;
