import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";

import "./app.css";
import './responsive.css';

//pages & components
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Calendar from "./pages/Calendar";
import Properties from "./pages/Properties";
import Clients from "./pages/Clients";
import Client from "./pages/Client";
import SingleVendor from './pages/Vendor';
import Property from "./pages/Property";
import Vendors from './pages/Vendors';
import AddProperty from "./pages/AddProperty";
import AddClient from "./pages/AddClient";
import AddVendor from './pages/AddVendor';
import PropFeatures from './pages/PropFeatures';
import Services from './pages/Services';

function App() {
  const { user } = useAuthContext();

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="pages">
          <Routes>
            <Route
              path="/"
              element={user ? <Home /> : <Navigate to="/login" />}
            />
            <Route
              path="/properties"
              element={<Properties />}
            />
            <Route
              path="/clients"
              element={<Clients />}
            />
            <Route
              path="/vendors"
              element={<Vendors />}
            />
            <Route
              path="/clients/:ownerId"
              element={<Client />}
            />
            <Route
              path="/vendors/:vendorId"
              element={<SingleVendor />}
            />
            <Route
              path="/properties/:propertyId"
              element={<Property />}
            />
            <Route
              path="/calendar"
              element={<Calendar />}
            />
            <Route
              path="/addproperty"
              element={<AddProperty />}
            />
            <Route
              path="/addclient"
              element={<AddClient />}
            />
            <Route
              path="/addvendor"
              element={<AddVendor />}
            />
            <Route
              path="/manage-features"
              element={<PropFeatures />}
            />
            <Route
              path="/manage-services"
              element={<Services />}
            />
            <Route
              path="/login"
              element={!user ? <Login /> : <Navigate to="/" />}
            />
            <Route
              path="/signup"
              element={user ? <Signup /> : <Navigate to="/login" />}
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
