import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useVendorsContext } from "../hooks/useVendorsContext";
import { useAuthContext } from "../hooks/useAuthContext";
import configData from '../config.json';

// Components
import VendorList from "../components/VendorList";

const Vendors = () => {
    const { vendors, dispatch } = useVendorsContext();
    const { user } = useAuthContext();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false)

        const fetchVendors = async () => {
            const response = await fetch(`${configData.SERVER_URL}/api/vendors`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            const json = await response.json();

            if (response.ok) {
                json.sort((a, b) => a.vendorName.localeCompare(b.vendorName))
                dispatch({ type: "SET_VENDORS", payload: json });
            }
        };

        if (user) {
            fetchVendors();
        }
    }, [dispatch, user]);

    return (
        <div>{!isLoading &&
            <div>
                {!user ? <Navigate to="/login" /> :
                    <div>
                        <Helmet>
                            <title>Vendors</title>
                        </Helmet>
                        <div className="note-header">
                            <h1>Vendor List</h1>
                            <Link className="button" to={'/addvendor'}>Add Vendor</Link>
                        </div>
                        <div>
                            <div className="vendor-grid">
                                {vendors &&
                                    vendors.map((vendor) => <VendorList key={vendor._id} vendor={vendor} />)}
                            </div>
                        </div>
                    </div>}
            </div>}
        </div>
    );
};

export default Vendors;
