import React from "react";

const PropDropdown = ({ value, options, onChange, identifier }) => {

  return (
    <select className="new-prop-select" value={value} onChange={onChange} >
      <option value={null} />
      {identifier === 'Second Owner' && <option value={'clear'} >Remove</option>}
      {options.map((option) => (
        // console.log(option.value[0])
        <option option className="new-prop-option" value={option.value} id={option.id} key={`${option.value}`} > {option.label}</option>
      ))
      }
    </select >
  );
};

export default PropDropdown;
