import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { usePropertiesContext } from "../hooks/usePropertiesContext";
import { useAuthContext } from "../hooks/useAuthContext";
import configData from '../config.json';

//Components
import PropertyList from "../components/PropertyList";

const Properties = () => {
  const { properties, dispatch } = usePropertiesContext();
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false)

    const fetchProperties = async () => {
      const response = await fetch(`${configData.SERVER_URL}/api/properties`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();

      if (response.ok) {
        json.sort((a, b) => a.propAddress1.localeCompare(b.propAddress1))
        dispatch({ type: "SET_PROPERTIES", payload: json });
      }
    };

    if (user) {
      fetchProperties();
    }
  }, [dispatch, user]);

  return (
    <div>{!isLoading &&
      <div>
        {!user ? <Navigate to="/login" /> :
          <div>
            <Helmet>
              <title>Properties</title>
            </Helmet>
            <div className="note-header">
              <h1>Properties</h1>
              <Link className="button" to={'/addproperty'}>Add Property</Link>
            </div>

            <div className="prop-grid">
              {properties &&
                properties.map((property) => (
                  <PropertyList key={property._id} property={property} />
                ))}
            </div>
          </div>}
      </div>}
    </div>
  );
};

export default Properties;
