import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet";
import configData from '../config.json';
import PropDropdown from "../components/PropDropdown";
import PropList from "../components/PropList";
import AddService from '../components/AddService';

import { useAuthContext } from "../hooks/useAuthContext";
import { useVendorsContext } from "../hooks/useVendorsContext";
import { useServiceContext } from "../hooks/useServiceContext";

import AddNoteButton from '../components/AddNote';
import Note from '../components/Note';

function SingleVendor() {
    var newServicesArray = [];

    const redirect = useNavigate();
    const location = useLocation();
    const path = location.pathname.split("/")[2];
    const [vendor, setVendor] = useState({});
    const { dispatch } = useVendorsContext();
    const { user } = useAuthContext();
    const [isLoading, setIsLoading] = useState(true);
    const [editStatus, setEditStatus] = useState(false);
    const [vendorName, setVendorName] = useState('');
    const [vendorAddress1, setVendorAddress1] = useState('');
    const [vendorAddress2, setVendorAddress2] = useState('');
    const [vendorCity, setVendorCity] = useState('');
    const [vendorState, setVendorState] = useState('');
    const [vendorZip, setVendorZip] = useState('');
    const [vendorContact, setVendorContact] = useState('');
    const [vendorPhone1, setVendorPhone1] = useState('');
    const [vendorPhone2, setVendorPhone2] = useState('');
    const [vendorWebsite, setVendorWebsite] = useState('');
    const [vendorServiceArray, setVendorServiceArray] = useState([]);
    const [newVendorName, setNewVendorName] = useState('');
    const [newVendorAddress1, setNewVendorAddress1] = useState('');
    const [newVendorAddress2, setNewVendorAddress2] = useState('');
    const [newVendorCity, setNewVendorCity] = useState('');
    const [newVendorState, setNewVendorState] = useState('');
    const [newVendorZip, setNewVendorZip] = useState('');
    const [newVendorContact, setNewVendorContact] = useState('');
    const [newVendorPhone1, setNewVendorPhone1] = useState('');
    const [newVendorPhone2, setNewVendorPhone2] = useState('');
    const [newVendorWebsite, setNewVendorWebsite] = useState('');
    const [newVendorServiceArray, setNewVendorServiceArray] = useState([]);
    const [newVendorService, setNewVendorService] = useState('');
    const [vendorServices, setVendorServices] = useState('')
    const [servicePopup, setServicePopup] = useState(false)
    const [error, setError] = useState(null);

    const { services, dispatch: serviceDispatch } = useServiceContext();


    const handleDelete = async () => {
        if (!user) {
            return;
        }
        const response = await fetch(`${configData.SERVER_URL}/api/vendors/` + vendor._id, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        });
        const json = await response.json();

        if (response.ok) {
            dispatch({ type: "DELETE_VENDOR", payload: json });
            redirect('/vendors');
        }
    };

    const cancelEdit = () => {
        setEditStatus(false)
        setNewVendorName('');
        setNewVendorAddress1('');
        setNewVendorAddress2('');
        setNewVendorCity('');
        setNewVendorState('');
        setNewVendorZip('');
        setNewVendorContact('');
        setNewVendorPhone1('');
        setNewVendorPhone2('');
        setNewVendorWebsite('');
        setNewVendorServiceArray(vendorServiceArray)
        setNewVendorService('');
        setVendorServices('')
    }

    // const addVendorService = (e) => {
    //     e.preventDefault();
    //     set([vendorServices, ...])
    //     setVendorServices('')
    // }

    const handleUpdate = async (e) => {
        console.log(vendor.vendorServices);
        setVendorName(vendor.vendorName);
        setVendorAddress1(vendor.vendorAddress1);
        setVendorAddress2(vendor.vendorAddress2);
        setVendorCity(vendor.vendorCity);
        setVendorState(vendor.vendorState);
        setVendorZip(vendor.vendorZip);
        setVendorContact(vendor.vendorContact);
        setVendorPhone1(vendor.vendorPhone1);
        setVendorPhone2(vendor.vendorPhone2);
        setVendorWebsite(vendor.vendorWebsite);
        setVendorServiceArray(vendorServices)
        setEditStatus(false)

        let vendorInfo = {}
        if (newVendorName !== '') {
            vendorInfo["vendorName"] = newVendorName;
        }
        if (newVendorAddress1 !== '') {
            vendorInfo["vendorAddress1"] = newVendorAddress1;
        }
        if (newVendorAddress2 !== '') {
            vendorInfo["vendorAddress2"] = newVendorAddress2;
        }
        if (newVendorCity !== '') {
            vendorInfo["vendorCity"] = newVendorCity;
        }
        if (newVendorState !== '') {
            vendorInfo["vendorState"] = newVendorState;
        }
        if (newVendorZip !== '') {
            vendorInfo["vendorZip"] = newVendorZip;
        }
        if (newVendorContact !== '') {
            vendorInfo["vendorContact"] = newVendorContact;
        }
        if (newVendorPhone1 !== '') {
            vendorInfo["vendorPhone1"] = newVendorPhone1;
        }
        if (newVendorPhone2 !== '') {
            vendorInfo["vendorPhone2"] = newVendorPhone2;
        }
        if (newVendorWebsite !== '') {
            vendorInfo["vendorWebsite"] = newVendorWebsite;
        }
        if (vendorServices !== []) {
            vendorInfo["vendorServices"] = vendorServices;
        }
        console.log(vendorInfo)
        const response = await fetch(`${configData.SERVER_URL}/api/vendors/` + vendor._id, {
            method: 'PUT',
            body: JSON.stringify(vendorInfo),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`,
            }
        })
        const json = await response.json()
        console.log(json)
        if (!response.ok) {
            console.log(json.error)
        }
        if (response.ok) {
            setEditStatus(false)
            setVendorName(newVendorName)
            setVendorAddress1(newVendorAddress1)
            setVendorAddress2(newVendorAddress2)
            setVendorCity(newVendorCity)
            setVendorState(newVendorState)
            setVendorZip(newVendorZip)
            setVendorContact(newVendorContact)
            setVendorPhone1(newVendorPhone1)
            setVendorPhone2(newVendorPhone2)
            setVendorWebsite(newVendorWebsite)
            setVendorServiceArray(newVendorServiceArray)
            dispatch({ type: 'UPDATE_VENDOR', payload: json })
            redirect('/vendors');
        }
    }

    const handleServiceChange = (e) => {
        console.log(e.target.value, vendor.vendorServices);
        e.preventDefault();
        if (e.target.value) {
            // let serviceArr = e.target.value.split(",");
            setVendorServices([e.target.value, ...vendor.vendorServices]);
            console.log(vendorServices)
        }
    };
    useEffect(() => {
        setIsLoading(false)
        setError('');

        const fetchVendor = async () => {
            // console.log(user);
            // console.log(localStorage.getItem("user"));

            let localUser = JSON.parse(localStorage.getItem("user"));
            const res = await axios.get(`${configData.SERVER_URL}/api/vendors/` + path, {
                headers: {
                    Authorization: `Bearer ${localUser.token}`,
                },
            });
            // console.log(res.data)
            res.data.vendor.notes = res.data.newAdminNotes
            // console.log(res.data.vendor)
            setVendor(res.data.vendor);
            console.log(vendor.vendorServices)
            setVendorServiceArray(vendor.vendorServices)
            setVendorServices(vendor.vendorServices)
            console.log(path);
        };
        const fetchOptions = async () => {
            const response = await fetch(`${configData.SERVER_URL}/api/services`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            const json = await response.json();

            if (response.ok) {
                // console.log(json)
                serviceDispatch({ type: "SET_SERVICES", payload: json });
            }
        };

        if (user) {
            fetchOptions();
            fetchVendor();
        }
        console.log(vendor);
    }, [vendor, dispatch, serviceDispatch, user, path]);

    if (services) {
        services.forEach((service) => {
            newServicesArray.push({ 'label': service.serviceName, 'value': service.serviceName })
        })
    }

    return (
        <div>{!isLoading &&
            <div>
                {!user ? <Navigate to="/login" /> :
                    <div>
                        <Helmet>
                            <title>{`Vendor - ${vendor.vendorName}`}</title>
                        </Helmet>
                        <h1>Vendor</h1>
                        <div className=" single-layout">
                            <div className="property-col-left">
                                <div>
                                    {editStatus ? <input
                                        type='text'
                                        placeholder={vendor.vendorName}
                                        onChange={(e) => setNewVendorName(e.target.value)}
                                        value={newVendorName}
                                    /> : <h4>
                                        {vendor.vendorName}
                                    </h4>}
                                    {editStatus ? <div>
                                        <button onClick={cancelEdit}>Cancel Edit</button>
                                        <button onClick={handleUpdate}>Submit Changes</button>
                                    </div> :
                                        <div className="property-button-row">
                                            <button onClick={() => { setEditStatus(true); setVendorServices(vendor.vendorServices) }}>Edit</button>
                                            <button onClick={handleDelete}>Delete</button>
                                        </div>}
                                    <div className="client-contact">
                                        {editStatus ? (
                                            <div className="client-contact-left">
                                                <p>
                                                    <strong>Address: </strong>
                                                </p>
                                                <input
                                                    type='text'
                                                    placeholder={vendor.vendorAddress1}
                                                    onChange={(e) => setNewVendorAddress1(e.target.value)}
                                                    value={newVendorAddress1}
                                                />
                                                <input
                                                    type='text'
                                                    placeholder={vendor.vendorAddress2}
                                                    onChange={(e) => setNewVendorAddress2(e.target.value)}
                                                    value={newVendorAddress2}
                                                />
                                                <div><input
                                                    type='text'
                                                    placeholder={vendor.vendorCity}
                                                    onChange={(e) => setNewVendorCity(e.target.value)}
                                                    value={newVendorCity}
                                                />, <input
                                                        type='text'
                                                        placeholder={vendor.vendorState}
                                                        onChange={(e) => setNewVendorState(e.target.value)}
                                                        value={newVendorState}
                                                    /> <input
                                                        type='text'
                                                        placeholder={vendor.vendorZip}
                                                        onChange={(e) => setNewVendorZip(e.target.value)}
                                                        value={newVendorZip}
                                                    /></div>
                                            </div>
                                        ) : (
                                            <div className="client-contact-left">
                                                <p>
                                                    <strong>Address: </strong>
                                                </p>
                                                <p>{vendor.vendorAddress1}</p>
                                                <p>{vendor.vendorAddress2}</p>

                                                <p>
                                                    {vendor.vendorCity}, {vendor.vendorState} {vendor.vendorZip}
                                                </p>
                                            </div>
                                        )}
                                        {editStatus ? (
                                            <div >
                                                <p>
                                                    <strong>Contact Info: </strong>
                                                </p>
                                                <input
                                                    type='text'
                                                    placeholder={vendor.vendorContact}
                                                    onChange={(e) => setNewVendorContact(e.target.value)}
                                                    value={newVendorContact}
                                                />
                                                <input
                                                    type='text'
                                                    placeholder={vendor.vendorPhone1}
                                                    onChange={(e) => setNewVendorPhone1(e.target.value)}
                                                    value={newVendorPhone1}
                                                />
                                                <input
                                                    type='text'
                                                    placeholder={vendor.vendorPhone2}
                                                    onChange={(e) => setNewVendorPhone2(e.target.value)}
                                                    value={newVendorPhone2}
                                                /> <input
                                                    type='text'
                                                    placeholder={vendor.vendorWebsite}
                                                    onChange={(e) => setNewVendorWebsite(e.target.value)}
                                                    value={newVendorWebsite}
                                                />

                                            </div>
                                        ) : (
                                            <div>
                                                <p>
                                                    <strong>Contact Info: </strong>
                                                </p>
                                                <p>{vendor.vendorContact}</p>
                                                <p>{vendor.vendorPhone1}</p>
                                                <p>{vendor.vendorPhone2 && vendor.vendorPhone2}</p>
                                                <a>{vendor.vendorWebsite}</a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="client-item-col-left client-property-layout">
                                <div className="note-header">
                                    <h4>Administrative Notes</h4>
                                    <AddNoteButton noteTarget={['Vendor Note', vendor._id, vendor.adminNotes]} />
                                </div>
                                {/* {console.log(vendor)} */}
                                {vendor.notes ? vendor.notes.map((notes) => <Note notes={['Vendor Notes', notes]} />)
                                    // <div className="notes">
                                    //     <p>Submitted on: {note.submittedOn}</p>
                                    //     <div dangerouslySetInnerHTML={{ __html: note.note }} />
                                    // </div>)
                                    : null}

                            </div>
                            {editStatus ? (
                                <div className="client-item-col-left client-property-layout">
                                    <h4>Services</h4>
                                    <div>
                                        {/* <input type="text" onChange={(e) => setVendorService(e.target.value)} value={vendorServices} />
                                        <button onClick={addVendorService}>Add Service</button> */}
                                        <PropDropdown

                                            options={newServicesArray}
                                            value={vendorServices}
                                            onChange={handleServiceChange}
                                        />
                                        <button onClick={(e) => { e.preventDefault(); setServicePopup(true) }}>Add New Service</button>
                                        <PropList
                                            optionSet={vendorServices}
                                        />
                                        {servicePopup && <div className="featurePopUp"><button onClick={(e) => { e.preventDefault(); setServicePopup(false) }}>Close Popup</button><AddService /></div>}
                                    </div>
                                    {/* <ul>
                                        { &&
                                            .map((service => <li>{service} <button class="material-symbols-outlined remove-service" onClick={e => {
                                                removeSelectedService(e, service);
                                                setVendorServices(service)
                                            }
                                            }>
                                                remove
                                            </button></li>))
                                        }
                                    </ul> */}
                                </div>
                            ) : (
                                <div className="client-item-col-left client-property-layout">
                                    <h4>Services</h4>
                                    {vendor.vendorServices ? (
                                        <ul>
                                            {vendor.vendorServices.map((service) =>
                                                // console.log(service);
                                                <li>{service}</li>
                                            )}
                                        </ul>
                                    ) : (
                                        <p>No Services currently associated with this vendor</p>
                                    )}

                                </div>
                            )}
                        </div>
                        <div className="client-item-col-left client-property-layout">
                            {vendor.vendorProperties ? (
                                <div>
                                    {vendor.vendorProperties[0] ? (
                                        <div>
                                            <h4>Properties</h4>
                                            <div className="client-contact">
                                                {vendor.vendorProperties.map((property) => (
                                                    <div className="client-properties">
                                                        <p className="client-prop-title">
                                                            {property.propAddress1}
                                                            {property.propAddress2
                                                                ? `, ${property.propAddress2}`
                                                                : null}
                                                        </p>
                                                        <p>
                                                            {property.propCity}, {property.propState}{" "}
                                                            {property.propZip}

                                                        </p>
                                                        <Link to={`/properties/${property._id}`}>
                                                            <button>View</button>
                                                        </Link>
                                                    </div>
                                                ))}

                                            </div>

                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </div>}
            </div>}
        </div>
    );
}

export default SingleVendor;
