import React from 'react'
import SingleClient from '../components/SingleClient'

const Client = () => {
  return (
    <div className="single-layout">
      <h1>Owner</h1>
      <SingleClient />
    </div>

  )
}

export default Client
