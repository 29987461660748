import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { BsPlusCircleFill } from "react-icons/bs";
import configData from '../config.json';

// Hooks
import { useAuthContext } from "../hooks/useAuthContext";
import { useServiceContext } from "../hooks/useServiceContext";

const AddService = () => {
    const [serviceName, setServiceName] = useState("");
    const [error, setError] = useState(null);
    const [emptyFields, setEmptyFields] = useState([]);
    const [success, setSuccess] = useState(false)

    const { user } = useAuthContext();
    const { dispatch: serviceDispatch } = useServiceContext();
    const [isLoading, setIsLoading] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user) {
            setError("You must be logged in");
            return;
        }

        let service = { serviceName }
        const response = await fetch(`${configData.SERVER_URL}/api/services`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(service)
        });
        const json = await response.json();

        if (!response.ok) {
            setError(json.error);
            setEmptyFields(json.emptyFields);
            setSuccess(false);
        }
        if (response.ok) {
            setServiceName("");
            setError('');
            setEmptyFields([]);
            setSuccess(true);
            serviceDispatch({ type: "CREATE_SERVICES", payload: json });
        }
    }

    useEffect(() => {
        setIsLoading(false)
    }, [serviceDispatch, user]);

    return (
        <div>{!isLoading &&
            <div>
                {!user ? <Navigate to="/login" /> :
                    <div >
                        <form onSubmit={handleSubmit} id="featureForm">
                            <h2>Add New Service</h2>
                            <div>
                                <label className="new-prop-label-title" htmlFor="">
                                    Service Name
                                </label>
                                <input type="text" onChange={(e) => setServiceName(e.target.value)} value={serviceName} />
                            </div>
                            <div className="add-button" onClick={handleSubmit}>
                                <BsPlusCircleFill />
                            </div>
                            <div>
                                <p>{error}</p>
                                {success && (
                                    <p>Service successfully added!</p>
                                )}
                                <ul>{emptyFields && emptyFields.map((field) => (<li>{field}</li>))}</ul>
                            </div>
                        </form>
                    </div>}
            </div>}
        </div>
    );
};

export default AddService;