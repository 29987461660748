import { VendorsContext } from "../context/VendorsContext";
import { useContext } from "react";

export const useVendorsContext = () => {
    const context = useContext(VendorsContext);

    if (!context) {
        throw Error(
            "useVendorsContext must be used inside VendorsContextProvider"
        );
    }

    return context;
};