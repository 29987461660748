import { createContext, useReducer } from "react";

export const OwnersContext = createContext();

export const ownersReducer = (state, action) => {
  switch (action.type) {
    case "SET_OWNERS":
      return {
        owners: action.payload,
      };
    case "CREATE_OWNER":
      return {
        owners: [action.payload, ...state.owners],
      };
    case "DELETE_OWNER":
      return {
        owners: state.owners.filter((o) => o._id !== action.payload._id),
      };
    case "SET_SERVICES":
      return {
        owners: [...state.owners],
        services: action.payload,
      };
    case 'UPDATE_OWNER':
      return {
        owners: action.payload
      }
    default:
      return state;
  }
};

export const OwnersContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ownersReducer, {
    owners: null,
    services: null
  });

  return (
    <OwnersContext.Provider value={{ ...state, dispatch }}>
      {children}
    </OwnersContext.Provider>
  );
};
