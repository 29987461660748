import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useOwnersContext } from "../hooks/useOwnersContext";
import { useAuthContext } from "../hooks/useAuthContext";
import configData from '../config.json';

// Components
import ClientList from "../components/ClientList";
import OwnerForm from "../components/ownerForm";

const Clients = () => {
  const { owners, dispatch } = useOwnersContext();
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false)

    const fetchOwners = async () => {
      const response = await fetch(`${configData.SERVER_URL}/api/owners`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();

      if (response.ok) {
        json.sort((a, b) => a.ownerLName.localeCompare(b.ownerLName) || a.ownerFName.localeCompare(b.ownerFName))
        dispatch({ type: "SET_OWNERS", payload: json });
      }
    };

    if (user) {
      fetchOwners();
    }
  }, [dispatch, user]);

  return (
    <div>{!isLoading &&
      <div>
        {!user ? <Navigate to="/login" /> :
          <div>
            <Helmet>
              <title>Owners</title>
            </Helmet>
            <div className="note-header">
              <h1>Owner List</h1>
              <Link className="button" to={'/addclient'}>Add Owner</Link>
            </div>
            <div>
              <div className='client-box'>
                {owners &&
                  owners.map((owner) => <ClientList key={owner._id} owner={owner} />)}
              </div>
            </div>
          </div>}
      </div>}
    </div>
  );
};

export default Clients;
