import React, { useEffect } from "react";
import { useOwnersContext } from "../hooks/useOwnersContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { Link } from "react-router-dom";
import configData from '../config.json';

//components
import CalendarComponent from "../components/CalendarComponent";
import NewEvent from '../components/AddEventButton';

const Home = () => {
  const { dispatch } = useOwnersContext();
  const { user } = useAuthContext();

  useEffect(() => {
    const fetchOwners = async () => {
      const response = await fetch(`${configData.SERVER_URL}/api/owners`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();

      if (response.ok) {
        dispatch({ type: "SET_OWNERS", payload: json });
      }
    };

    if (user) {
      fetchOwners();
    }
  }, [dispatch, user]);

  return (
    <div>
      <div className="home">
        <div className="home-calendar-details">
          <div className="calendar-header">
            <h3>Calendar</h3>
            <NewEvent />
          </div>
          <CalendarComponent />
        </div>
        {/* <div className="owners">
          {owners &&
            owners.map((owner) => <OwnerDetails key={owner._id} owner={owner} />)}
        </div> */}
        <div className="homeBtnGroup">
          <Link className="button" to={'/addproperty'}>Add Property</Link>
          <Link className="button" to={'/addclient'}>Add Owner</Link>
          <Link className="button" to={'/addvendor'}>Add Vendor</Link>
          <Link className="button" to="/signup">Add User</Link>
          <Link className="button" to={'/manage-features'}>Manage Property Features</Link>
          <Link className="button" to={'/manage-services'}>Manage Vender Services</Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
