import { PropertiesContext } from "../context/PropertiesContext";
import { useContext } from "react";

export const usePropertiesContext = () => {
  const context = useContext(PropertiesContext);

  if (!context) {
    throw Error(
      "usePropertiesContext must be used inside PropertiesContextProvider"
    );
  }

  return context;
};