import { Link } from "react-router-dom";
import { usePropertiesContext } from "../hooks/usePropertiesContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { BsTrash } from "react-icons/bs";
import configData from '../config.json';

//date fns
//import formatDistanceToNow from "date-fns/formatDistanceToNow";

const PropertyList = ({ property }) => {
  const { dispatch } = usePropertiesContext();
  const { user } = useAuthContext();

  const handleClick = async () => {
    if (!user) {
      return;
    }
    const response = await fetch(`${configData.SERVER_URL}/api/properties/` + property._id, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();
    console.log(json)

    if (response.ok) {
      // console.log('hit', json)
      dispatch({ type: "DELETE_PROPERTY", payload: json });
    }
  };

  return (
    <div className="owner-details props-in-grid">
      <div className="client-item-layout ">
        <div>
          <h4>{property.propAddress1}</h4>
          <p>{property.propAddress2}</p>
          <p>
            {property.propCity}, {property.propState} {property.propZip}
          </p>
        </div>
        {/* <div className="client-item-col-right">
          <p>
            <strong>Emergency Info: </strong>
          </p>
          <p>{property.propEmerName}</p>
          <p>{property.propEmerPhone}</p>
          <p>{property.propEmerEmail}</p>
        </div> */}
      </div>

      <div className="prop-buttons">
        <span>
          <Link to={`/properties/${property._id}`}>
            <button className="">View</button>
          </Link>
        </span>
        {/* <span className="icon" onClick={handleClick}>
          <BsTrash className="trash-icon" />
        </span> */}
      </div>
    </div>
  );
};

export default PropertyList;
