import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router";
import configData from '../config.json';
import $ from 'jquery';

//hooks
import { useCalendarContext } from "../hooks/useCalContext.js";
import { useAuthContext } from "../hooks/useAuthContext";
import { usePropertiesContext } from "../hooks/usePropertiesContext";
import { useVendorsContext } from "../hooks/useVendorsContext";
import { useServiceContext } from "../hooks/useServiceContext";

// Components
import PropDropdown from './PropDropdown.js';
import PropList from "./PropList";

const AddEventButton = (addressObj, startDate) => {
  var newServicesArray = [];
  var newVendorArray = [];
  const address = addressObj.address
  const { dispatch: calDispatch } = useCalendarContext();
  const { user } = useAuthContext();
  const { properties, dispatch } = usePropertiesContext();
  const { vendors, dispatch: vendorDispatch } = useVendorsContext();
  const { services, dispatch: serviceDispatch } = useServiceContext();

  const [propAddress, setPropAddress] = useState('')
  const [popup, setPopup] = useState(false);
  const [status, setStatus] = useState("Submit");
  const [title, setTitle] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);

  const [done, setDone] = useState(false)

  const redirect = useNavigate();
  const webLocation = useLocation();
  const path = webLocation.pathname.split("/")[2];
  const [eventVendor, setEventVendor] = useState('');
  const [vendorServiceArray, setVendorServiceArray] = useState([]);
  const [vendorServices, setVendorServices] = useState('')

  function resetSelectElement() {
    if (!address) {
      var dropDown = document.getElementById("location");
      dropDown.selectedIndex = 0;
    }
  }

  const handleServiceChange = (e) => {
    e.preventDefault();
    if (e.target.value) {
      let serviceArr = e.target.value.split(",");
      let serviceObj = { 'name': serviceArr[0], 'id': serviceArr[1] };
      // setVendorServices([serviceObj, ...vendorServices]);
      setVendorServices([serviceArr[0], ...vendorServices]);
      console.log(vendorServices)
    }
  };

  const handleVendorChange = (event) => {
    console.log(vendors, event.target.value)
    setEventVendor(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");

    if (address) {
      var details = { title, start, end, 'location': address, 'notes': description, 'service': vendorServices, 'vendor': eventVendor };
    } else { var details = { title, start, end, location, 'notes': description, 'service': vendorServices, 'vendor': eventVendor }; }
    // console.log(details)
    let response = await fetch(`${configData.SERVER_URL}/api/events`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    const json = await response.json();
    if (!response.ok) {
      // alert(!response.ok)
      // console.log(json.error);
      setError(json.error);
      setEmptyFields(json.emptyFields);
      // console.log(emptyFields);
    }
    if (response.ok) {
      // alert(response.ok)
      // console.log(json);
      setTitle("");
      setStart("");
      setEnd("");
      setDescription('');
      // setLocation('');
      setError(null);
      setEmptyFields([]);
      calDispatch({ type: "CREATE_EVENT", payload: json });
      resetSelectElement();
      setPopup(false);
    }
  };

  useEffect(() => {
    const fetchProperties = async () => {
      const response = await fetch(`${configData.SERVER_URL}/api/properties`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();

      if (response.ok) {
        json.sort((a, b) => a.propAddress1.localeCompare(b.propAddress1));
        dispatch({ type: "SET_PROPERTIES", payload: json });
      }
    };

    const fetchVendors = async () => {
      const response = await fetch(`${configData.SERVER_URL}/api/vendors`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();

      if (response.ok) {
        json.sort((a, b) => a.vendorName.localeCompare(b.vendorName))
        vendorDispatch({ type: "SET_VENDORS", payload: json });
      }
    };
    const fetchServices = async () => {
      const response = await fetch(`${configData.SERVER_URL}/api/services`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();

      if (response.ok) {
        json.sort((a, b) => a.serviceName.localeCompare(b.serviceName))
        // console.log(json)
        serviceDispatch({ type: "SET_SERVICES", payload: json });
      }
    };

    if (user) {
      fetchProperties();
      fetchVendors();
      fetchServices();
    }
  }, [vendorDispatch, serviceDispatch, dispatch, user]);

  if (vendors) {
    vendors.forEach((vendor) => {
      newVendorArray.push({ 'label': vendor.vendorName, 'value': vendor.vendorName })
    })
    newVendorArray.sort((a, b) => a.label.localeCompare(b.label));
  }
  if (services) {
    services.forEach((service) => {
      newServicesArray.push({ 'label': service.serviceName, 'value': service.serviceName })
    })
    newServicesArray.sort((a, b) => a.label.localeCompare(b.label));
  }
  // if (properties) {
  //   properties.forEach((property) => {
  //     serviceArray.push({ 'label': property.serviceName, 'value': [`${property.serviceName}`, `${property._id}`], 'id': property._id })
  //   })
  // }
  const today = new Date().toISOString();
  console.log(today)
  let formattedDate = today.slice(0, 16)
  if (popup) {
    if (!done) {
      setStart(formattedDate);
      setEnd(formattedDate);
      setDone(true)
    }
  }

  return (
    <div>
      {!popup ?
        <button id='new_event' className='' onClick={(info) => { setPopup(true); setDone(false); setStart(info) }}>
          New Event
        </button>
        :
        null}
      {popup ? (
        <div className='event-popup-wrapper'>
          <form onSubmit={handleSubmit} className="conference-form popup">
            <h2>Add Event</h2>
            <div className="single-label-group">
              <label className="new-prop-label-title" htmlFor="title">Title:</label>
              <input
                type="text"
                id="title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                required
              />
            </div>
            <div className="single-label-group">
              <label className="new-prop-label-title" htmlFor="description">Description:</label>
              <textarea
                type="text"
                id="description"
                rows="4" cols="50"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div>

            <div className="multi-label-group">
              <div>
                <label className="new-prop-label-title" htmlFor="description">Service:</label>
                <PropDropdown
                  options={newServicesArray}
                  value={vendorServices}
                  onChange={handleServiceChange}
                />
                <PropList
                  optionSet={vendorServices}
                />
              </div>
              <div>
                <label className="new-prop-label-title" htmlFor="description">Vendor:</label>
                <PropDropdown
                  options={newVendorArray}
                  value={vendorServices}
                  onChange={handleVendorChange}
                />
                <p>{eventVendor}</p>
              </div>
            </div>

            <div className="multi-label-group">
              <div>
                <label className="new-prop-label-title" htmlFor="start">Start Date/Time:</label>
                <input
                  type="datetime-local"
                  id="startDateTime"
                  min={today}
                  onChange={(e) => setStart(e.target.value)}
                  value={start}
                />
                {console.log(today)}
              </div>
              <div>
                <label className="new-prop-label-title" htmlFor="end">End Date/Time:</label>
                <input
                  type="datetime-local"
                  id="endDateTime"
                  min={start}
                  onChange={(e) => setEnd(e.target.value)}
                  value={end}
                />
              </div>
            </div>
            {!address ? <div className="single-label-group">
              <label className="new-prop-label-title" htmlFor="location">Location:</label>
              <select className="new-prop-select" id="location" onChange={(e) => setLocation(e.target.value)}>
                <option>Select Property</option>
                {/* <PropDropdown /> */}
                {properties && properties.map((prop) =>
                  <option className="new-prop-option" value={prop.propAddress1}>{prop.propAddress1}</option>
                )}
              </select>
            </div> : <p>Location: {address}</p>}
            <div className='popup-button-group'>
              <button type="submit">{status}</button>
              <button onClick={() => { setPopup(false); setDone(false); }}>Close Popup</button>
            </div>
          </form>
        </div>
      ) : null}
    </div>
  )
}

export default AddEventButton