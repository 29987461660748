import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import PropDropdown from "../components/PropDropdown";
import PropList from "../components/PropList";
import { BsPlusCircleFill } from "react-icons/bs";
import configData from '../config.json';
import AddService from '../components/AddService';

// Hooks
import { useAuthContext } from "../hooks/useAuthContext";
import { useServiceContext } from "../hooks/useServiceContext";

const AddVendor = () => {
    var newServicesArray = [];

    const [vendorName, setVendorName] = useState("");
    const [vendorAddress1, setVendorAddress1] = useState("");
    const [vendorAddress2, setVendorAddress2] = useState("");
    const [vendorCity, setVendorCity] = useState("");
    const [vendorState, setVendorState] = useState("");
    const [vendorZip, setVendorZip] = useState("");
    const [vendorContact, setVendorContact] = useState("");
    const [vendorPhone1, setVendorPhone1] = useState("");
    const [vendorPhone2, setVendorPhone2] = useState("");
    const [vendorWebsite, setVendorWebsite] = useState("");
    // const [vendorServices, setVendorService] = useState('');
    const [vendorServices, setVendorServices] = useState([]);
    const [vendorServicePreview, setVendorServicePreview] = useState([])
    const [error, setError] = useState(null);
    const [emptyFields, setEmptyFields] = useState([]);
    const [vendorID, setVendorID] = useState('')
    const [servicePopup, setServicePopup] = useState(false)

    const { user, dispatch } = useAuthContext();
    const { services, dispatch: serviceDispatch } = useServiceContext();
    console.log(vendorServices)
    const [isLoading, setIsLoading] = useState(true);

    // const addVendorService = (e) => {
    //     e.preventDefault();
    //     if (vendorServices === '') {
    //         return
    //     }
    //     setVendorServicePreview([vendorServices, ...vendorServicePreview])
    //     setVendorService('')
    // }
    const removeSelectedService = (e, service) => {
        e.preventDefault();
        // let serviceArray = vendorServicePreview
        let serviceArray = vendorServices
        serviceArray.splice(serviceArray.indexOf(service), 1)
        console.log(serviceArray)
        // setVendorServicePreview(serviceArray)
        setVendorServices(serviceArray)
        setVendorServices(services)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user) {
            setError("You must be logged in");
            return;
        }
        if (!vendorPhone1) {
            if (vendorPhone2) {
                var vendor = {
                    vendorName, vendorAddress1, vendorAddress2, vendorCity, vendorState, vendorZip, vendorContact, 'vendorPhone1': vendorPhone2, 'ownerPhone2': '', vendorWebsite,
                    // 'vendorServices': vendorServicePreview 
                    vendorServices
                }
                setVendorPhone1(vendorPhone2)
                setVendorPhone2('')
            }
        } else {
            var vendor = {
                vendorName, vendorAddress1, vendorAddress2, vendorCity, vendorState, vendorZip, vendorContact, vendorPhone1, vendorPhone2, vendorWebsite,
                // 'vendorServices': vendorServicePreview 
                vendorServices
            }
        }
        // const vendor = { vendorName, clientAddress1, vendorAddress2, vendorCity, vendorState, vendorZip, vendorContact, clientPhone1, vendorPhone2, vendorWebsite, 'vendorServices': vendorServicePreview }
        console.log(vendor)

        const response = await fetch(`${configData.SERVER_URL}/api/vendors`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(vendor)
        });
        const json = await response.json();

        if (!response.ok) {
            setError(json.error);
            setEmptyFields(json.emptyFields);
        }
        if (response.ok) {
            setVendorID(json._id)
            setVendorName("");
            setVendorAddress1("");
            setVendorAddress2("");
            setVendorCity("");
            setVendorState("");
            setVendorZip("");
            setVendorContact('');
            setVendorPhone1("");
            setVendorPhone2("");
            setVendorWebsite("");
            setVendorServices('');
            // setVendorServicePreview([]);
            setVendorServices([]);
            setError('');
            setEmptyFields([]);
        }
    }

    const handleServiceChange = (e) => {
        e.preventDefault();
        if (e.target.value) {
            let serviceArr = e.target.value.split(",");
            let serviceObj = { 'name': serviceArr[0], 'id': serviceArr[1] };
            // setVendorServices([serviceObj, ...vendorServices]);
            setVendorServices([serviceArr[0], ...vendorServices]);
            console.log(vendorServices)
        }
    };

    useEffect(() => {
        setIsLoading(false);
        setError('');

        const fetchOptions = async () => {
            const response = await fetch(`${configData.SERVER_URL}/api/services`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            const json = await response.json();

            if (response.ok) {
                // console.log(json)
                serviceDispatch({ type: "SET_SERVICES", payload: json });
            }
        };

        if (user) {
            fetchOptions();
        }
    }, [dispatch, serviceDispatch, user]);
    // console.log(services)

    if (services) {
        services.forEach((service) => {
            newServicesArray.push({ 'label': service.serviceName, 'value': service.serviceName })
        })
        newServicesArray.sort((a, b) => a.label.localeCompare(b.label))
    }

    return (
        <div>{!isLoading &&
            <div>
                {!user ? <Navigate to="/login" /> :
                    <div>
                        <h1>Add New Vendor</h1>
                        <div className="add-property-wrapper">
                            <form action="" id="clientForm">
                                <div className="new-property-grid">
                                    <div className="new-property-grid-col">
                                        <label className="new-prop-label-title" htmlFor="">
                                            Vendor Name
                                        </label>
                                        <input type="text" onChange={(e) => setVendorName(e.target.value)} value={vendorName} />
                                        <br />
                                        <label htmlFor="">Address 1</label>
                                        <input type="text" onChange={(e) => setVendorAddress1(e.target.value)} value={vendorAddress1} />
                                        <label htmlFor="">Address 2</label>
                                        <input type="text" onChange={(e) => setVendorAddress2(e.target.value)} value={vendorAddress2} />
                                        <label htmlFor="">City</label>
                                        <input type="text" onChange={(e) => setVendorCity(e.target.value)} value={vendorCity} />
                                        <div className="new-prop-input-2col">
                                            <div>
                                                <label htmlFor="">State</label>
                                                <input className="input-space" type="text" onChange={(e) => setVendorState(e.target.value)} value={vendorState} />
                                            </div>
                                            <div>
                                                <label htmlFor="">Zip</label>
                                                <input type="text" onChange={(e) => setVendorZip(e.target.value)} value={vendorZip} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="new-property-grid">
                                        <div className="new-property-grid-col">
                                            <p className="new-prop-label-title">Contact Info</p>
                                            <label htmlFor="">Primary Contact</label>
                                            <input type="text" onChange={(e) => setVendorContact(e.target.value)} value={vendorContact} />
                                            <label htmlFor="">Primary Phone</label>
                                            <input type="text" onChange={(e) => setVendorPhone1(e.target.value)} value={vendorPhone1} />
                                            <label htmlFor="">Secondary Phone</label>
                                            <input type="text" onChange={(e) => setVendorPhone2(e.target.value)} value={vendorPhone2} />
                                            <label htmlFor="">Website</label>
                                            <input type="text" onChange={(e) => setVendorWebsite(e.target.value)} value={vendorWebsite} />
                                            <label htmlFor="">Services</label>
                                            {/* <div>
                                                <input type="text" onChange={(e) => setVendorService(e.target.value)} value={vendorServices} />
                                                <button onClick={addVendorService}>Add Service</button>
                                            </div> */}
                                            <PropDropdown

                                                options={newServicesArray}
                                                value={vendorServices}
                                                onChange={handleServiceChange}
                                            />
                                            <button onClick={(e) => { e.preventDefault(); setServicePopup(true) }}>Add New Service</button>
                                            <PropList
                                                optionSet={vendorServices}
                                            />
                                            {servicePopup && <div className="featurePopUp"><button onClick={(e) => { e.preventDefault(); setServicePopup(false) }}>Close Popup</button><AddService /></div>}
                                            {/* <ul>
                                                {vendorServicePreview &&
                                                    vendorServicePreview.map((service => <li>{service} <button class="material-symbols-outlined remove-service" onClick={e => removeSelectedService(e, service)}>
                                                        remove
                                                    </button></li>))
                                                }
                                            </ul> */}
                                        </div>
                                    </div>
                                    <div className="add-button" onClick={handleSubmit}>
                                        <BsPlusCircleFill />
                                    </div>
                                    <div>
                                        <p>{error}</p>
                                        {!vendorID ? (
                                            null
                                        ) : (
                                            <div>
                                                <p>Vendor successfully added!</p>
                                                <Link className="button" to={`/vendors/${vendorID}`}>View Vendor</Link>
                                            </div>
                                        )}
                                        <ul>{emptyFields && emptyFields.map((field) => (<li>{field}</li>))}</ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>}
            </div>}
        </div>
    );
};

export default AddVendor;
