import React from "react";

import { useServiceContext } from "../hooks/useServiceContext";


const PropList = ({ optionSet }) => {
    const { services, dispatch } = useServiceContext();
    console.log(services)
    console.log(optionSet)
    const removeOption = (name) => {
        console.log(services)
        let arr = optionSet;
        arr.splice(arr.indexOf(name), 1);
        // console.log(arr);
        dispatch({ type: "UPDATE_OPTIONS", payload: arr });
        console.log(services)
    }

    return (
        < ul >
            {optionSet ?
                optionSet.map((option) => (
                    <div>
                        {typeof option === 'string' ?

                            <li>{option}<span class="material-symbols-outlined" onClick={() => {
                                removeOption(option)
                            }}>
                                delete
                            </span></li>
                            : <li>{option.name}<span class="material-symbols-outlined" onClick={() => {
                                removeOption(option)
                            }}>
                                delete
                            </span></li>}
                    </div>
                )) : null}
        </ ul>
    );
};

export default PropList;
