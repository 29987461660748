import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet";
import { useOwnersContext } from "../hooks/useOwnersContext";
import configData from '../config.json';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import 'draft-js/dist/Draft.css';

import { useAuthContext } from "../hooks/useAuthContext";

import AddNoteButton from './AddNote';
import Note from './Note';

function SingleClient() {
  const redirect = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [owner, setOwner] = useState({});
  const { user } = useAuthContext();
  const { dispatch } = useOwnersContext();
  const [isLoading, setIsLoading] = useState(true);

  const [editStatus, setEditStatus] = useState(false)
  const [ownerFName, setOwnerFName] = useState('')
  const [newOwnerFName, setNewOwnerFName] = useState('')
  const [ownerLName, setOwnerLName] = useState('')
  const [newOwnerLName, setNewOwnerLName] = useState('')
  const [ownerAddress1, setOwnerAddress1] = useState('')
  const [newOwnerAddress1, setNewOwnerAddress1] = useState('')
  const [ownerAddress2, setOwnerAddress2] = useState('')
  const [newOwnerAddress2, setNewOwnerAddress2] = useState('')
  const [ownerCity, setOwnerCity] = useState('')
  const [newOwnerCity, setNewOwnerCity] = useState('')
  const [ownerState, setOwnerState] = useState('')
  const [newOwnerState, setNewOwnerState] = useState('')
  const [ownerZip, setOwnerZip] = useState('')
  const [newOwnerZip, setNewOwnerZip] = useState('')
  const [ownerPhone1, setOwnerPhone1] = useState('')
  const [ownerPhone2, setOwnerPhone2] = useState('')
  const [newOwnerPhone1, setNewOwnerPhone1] = useState('')
  const [newOwnerPhone2, setNewOwnerPhone2] = useState('')
  const [ownerEmail, setOwnerEmail] = useState('')
  const [newOwnerEmail, setNewOwnerEmail] = useState('')

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  useEffect(() => {
    setIsLoading(false)

    const fetchOwner = async () => {
      // console.log(user);
      // console.log(localStorage.getItem("user"));

      let localUser = JSON.parse(localStorage.getItem("user"));
      const res = await axios.get(`${configData.SERVER_URL}/api/owners/` + path, {
        headers: {
          Authorization: `Bearer ${localUser.token}`,
        },
      });
      setOwner(res.data);
      // setOwnerFName(owner.ownerFName)
      // setOwnerLName(owner.ownerLName)
      // setOwnerAddress1(owner.ownerAddress1)
      // setOwnerAddress2(owner.ownerAddress2)
      // setOwnerCity(owner.ownerCity)
      // setOwnerState(owner.ownerState)
      // setOwnerZip(owner.ownerZip)
      // setOwnerPhone1(owner.ownerPhone1)
      // setOwnerPhone2(owner.ownerPhone2)
      // setOwnerEmail(owner.ownerEmail)
      console.log(path);
    };
    fetchOwner();
    console.log(owner);
  }, [path]);

  const handleDelete = async () => {
    if (!user) {
      return;
    }
    const response = await fetch(`${configData.SERVER_URL}/api/owners/` + owner._id, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();

    if (response.ok) {
      dispatch({ type: "DELETE_OWNER", payload: json });
      redirect('/clients');
    }
  };
  const cancelEdit = () => {
    setEditStatus(false)
    setNewOwnerFName('')
    setNewOwnerLName('')
    setNewOwnerAddress1('')
    setNewOwnerAddress2('')
    setNewOwnerCity('')
    setNewOwnerState('')
    setNewOwnerZip('')
    setNewOwnerPhone1('')
    setNewOwnerPhone2('')
    setNewOwnerEmail('')
  }
  const handleUpdate = async (e) => {
    setOwnerFName(owner.ownerFName)
    setOwnerLName(owner.ownerLName)
    setOwnerAddress1(owner.ownerAddress1)
    setOwnerAddress2(owner.ownerAddress2)
    setOwnerCity(owner.ownerCity)
    setOwnerState(owner.ownerState)
    setOwnerZip(owner.ownerZip)
    setOwnerPhone1(owner.ownerPhone1)
    setOwnerPhone2(owner.ownerPhone2)
    setOwnerEmail(owner.ownerEmail)
    // e.preventDefault();
    setEditStatus(false)
    console.log(newOwnerFName, owner.ownerLName)
    let clientInfo = {}
    if (newOwnerFName !== '') {
      clientInfo["ownerFName"] = newOwnerFName;
    }
    if (newOwnerLName !== '') {
      clientInfo["ownerLName"] = newOwnerLName;
    }
    if (newOwnerAddress1 !== '') {
      clientInfo["ownerAddress1"] = newOwnerAddress1;
    }
    if (newOwnerAddress2 !== '') {
      clientInfo["ownerAddress2"] = newOwnerAddress2;
    }
    if (newOwnerCity !== '') {
      clientInfo["ownerCity"] = newOwnerCity;
    }
    if (newOwnerState !== '') {
      clientInfo["ownerState"] = newOwnerState;
    }
    if (newOwnerZip !== '') {
      clientInfo["ownerZip"] = newOwnerZip;
    }
    if (newOwnerPhone1 !== '') {
      clientInfo["ownerPhone1"] = newOwnerPhone1;
    }
    if (newOwnerPhone2 !== '') {
      clientInfo["ownerPhone2"] = newOwnerPhone2;
    }
    if (newOwnerEmail !== '') {
      clientInfo["ownerEmail"] = newOwnerEmail;
    }
    if (newOwnerEmail !== '') {
      clientInfo["ownerEmail"] = newOwnerEmail;
    }

    console.log(clientInfo)
    const response = await fetch(`${configData.SERVER_URL}/api/owners/` + owner._id, {
      method: 'PUT',
      body: JSON.stringify(clientInfo),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      }
    })
    const json = await response.json()
    console.log(json)
    if (!response.ok) {
      console.log(json.error)
    }
    if (response.ok) {
      setEditStatus(false)
      setOwnerFName(newOwnerFName)
      setOwnerLName(newOwnerLName)
      setOwnerAddress1(newOwnerAddress1)
      setOwnerAddress2(newOwnerAddress2)
      setOwnerCity(newOwnerCity)
      setOwnerState(newOwnerState)
      setOwnerZip(newOwnerZip)
      setOwnerPhone1(newOwnerPhone1)
      setOwnerPhone2(newOwnerPhone2)
      setOwnerEmail(newOwnerEmail)
      dispatch({ type: 'UPDATE_OWNER', payload: json })
      redirect('/clients');
      // console.log(orgName, orgAddress1, orgAddress2, orgCity, orgState, orgZip, orgPhone)
    }
  }

  return (
    <div>{!isLoading &&
      <div>
        {!user ? <Navigate to="/login" /> :
          <div>
            <Helmet>
              <title>{`Owner - ${owner.ownerFName} ${owner.ownerLName}`}</title>
            </Helmet>
            <div className="client-item-layout">
              <div className="property-col-left">
                <div>
                  {editStatus ? <div>
                    <input
                      type='text'
                      placeholder={owner.ownerFName}
                      onChange={(e) => setNewOwnerFName(e.target.value)}
                      value={newOwnerFName}
                    />
                    <input
                      type='text'
                      placeholder={owner.ownerLName}
                      onChange={(e) => setNewOwnerLName(e.target.value)}
                      value={newOwnerLName}
                    />
                  </div>
                    :
                    <h4>
                      {owner.ownerFName} {owner.ownerLName}
                    </h4>
                  }
                  {editStatus ? <div>
                    <button onClick={cancelEdit}>Cancel Edit</button>
                    <button onClick={handleUpdate}>Submit Changes</button>
                  </div> :
                    <div className="property-button-row">
                      <button onClick={() => setEditStatus(true)}>Edit</button>
                      <button onClick={handleDelete}>Delete</button>
                    </div>}
                  <div className="client-contact">
                    <div className="client-contact-left">
                      <p>
                        <strong>Address: </strong>
                      </p>
                      {editStatus ? <div>
                        <input
                          type='text'
                          placeholder={owner.ownerAddress1}
                          onChange={(e) => setNewOwnerAddress1(e.target.value)}
                          value={newOwnerAddress1}
                        />
                        <input
                          type='text'
                          placeholder={owner.ownerAddress2}
                          onChange={(e) => setNewOwnerAddress2(e.target.value)}
                          value={newOwnerAddress2}
                        />
                        <div><input
                          type='text'
                          placeholder={owner.ownerCity}
                          onChange={(e) => setNewOwnerCity(e.target.value)}
                          value={newOwnerCity}
                        />, <input
                            type='text'
                            placeholder={owner.ownerState}
                            onChange={(e) => setNewOwnerState(e.target.value)}
                            value={newOwnerState}
                          /> <input
                            type='text'
                            placeholder={owner.ownerZip}
                            onChange={(e) => setNewOwnerZip(e.target.value)}
                            value={newOwnerZip}
                          /></div>
                      </div>
                        : <div>
                          <p>{owner.ownerAddress1}</p>
                          <p>{owner.ownerAddress2}</p>

                          <p>
                            {owner.ownerCity}, {owner.ownerState} {owner.ownerZip}
                          </p>
                        </div>}
                    </div>
                    <div>
                      <p>
                        <strong>Contact Info: </strong>
                      </p>
                      {editStatus ? <div>
                        <div><input
                          type='text'
                          placeholder={owner.ownerPhone1}
                          onChange={(e) => setNewOwnerPhone1(e.target.value)}
                          value={newOwnerPhone1}
                        /></div>
                        <div><input
                          type='text'
                          placeholder={owner.ownerPhone2}
                          onChange={(e) => setNewOwnerPhone2(e.target.value)}
                          value={newOwnerPhone2}
                        /></div>
                        <div><input
                          type='text'
                          placeholder={owner.ownerEmail}
                          onChange={(e) => setNewOwnerEmail(e.target.value)}
                          value={newOwnerEmail}
                        /></div>
                      </div> : <div>
                        <p>{owner.ownerPhone1}</p>
                        <p>{owner.ownerPhone2 && owner.ownerPhone2}</p>
                        <p>{owner.ownerEmail}</p>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="client-item-col-left client-property-layout">
              <div className="note-header">
                <h4>Administrative Notes</h4>
                <AddNoteButton noteTarget={['Client Note', owner._id, owner.adminNotes]} />
              </div>
              {console.log(owner)}
              {owner.notes ? owner.notes.map((notes) =>
                <Note notes={['Client Notes', notes]} />)
                // <div className="notes">
                //   <p>Submitted on: {note.submittedOn}</p>
                //   <div dangerouslySetInnerHTML={{ __html: note.note }} />
                // </div>)
                : null}

            </div>
            <div className="client-item-col-left client-property-layout">
              {owner.ownerProperties ? (
                <div>
                  {owner.ownerProperties[0] ? (
                    <div>
                      <h4>Properties</h4>
                      <div className="client-contact">
                        {owner.ownerProperties.map((property) => (
                          <div className="client-properties">
                            <p className="client-prop-title">
                              {property.propAddress1}
                              {property.propAddress2
                                ? `, ${property.propAddress2}`
                                : null}
                            </p>
                            <p>
                              {property.propCity}, {property.propState}{" "}
                              {property.propZip}

                            </p>
                            <Link to={`/properties/${property._id}`}>
                              <button>View</button>
                            </Link>
                          </div>
                        ))}

                      </div>

                    </div>
                  ) : null}
                </div>
              ) : null}

              {/* <p>
          <strong>Address: </strong>
        </p>
        <p>9 Straight Creek Ct</p>
        <p>
          Trophy Club, TX 76262
        </p> */}
            </div>
          </div>}
      </div>}
    </div>
  );
}

export default SingleClient;
