import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router";
import FullCalendar, { formatIsoTimeString } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';
import configData from '../config.json';
import { Tooltip } from "bootstrap";
import $ from 'jquery';
//import { format } from "date-fns";

// Components
import PropDropdown from './PropDropdown.js';
import PropList from "./PropList";

import { useCalendarContext } from "../hooks/useCalContext.js";
import { useAuthContext } from "../hooks/useAuthContext";
import { useVendorsContext } from "../hooks/useVendorsContext";
import { useServiceContext } from "../hooks/useServiceContext";

import "./calendarRender.css";

const CalendarComponent = () => {
  var newServicesArray = [];
  var newVendorArray = [];
  let tooltipInstance = null;
  const { calendar, dispatch } = useCalendarContext();
  const { vendors, dispatch: vendorDispatch } = useVendorsContext();
  const { services, dispatch: serviceDispatch } = useServiceContext();
  const { user } = useAuthContext();
  const [display, setDisplay] = useState("");
  const [editEvent, setEditEvent] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [location, setLocation] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [newStart, setNewStart] = useState("");
  const [newEnd, setNewEnd] = useState("");
  const [newLocation, setNewLocation] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [eventID, setEventID] = useState("");
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [newEvent, setNewEvent] = useState(false);
  const [status, setStatus] = useState("Submit")
  const [ownerConfirmed, setOwnerConfirmed] = useState(false);
  const [newOwnerConfirm, setNewOwnerConfirm] = useState(false);
  const [dateClicked, setDateClicked] = useState(false)

  const redirect = useNavigate();
  const webLocation = useLocation();
  const path = webLocation.pathname.split("/")[2];
  const [eventVendor, setEventVendor] = useState('');
  const [newEventVendor, setNewEventVendor] = useState('');
  const [vendorServiceArray, setVendorServiceArray] = useState([]);
  const [vendorServices, setVendorServices] = useState('');
  const [newVendorServices, setNewVendorServices] = useState('');

  const handleCheckChange = () => {
    console.log(newOwnerConfirm);
    setNewOwnerConfirm(!newOwnerConfirm);
  };

  const handleMouseEnter = (info) => {
    if (info.event._def.title) {
      if (info.event._def.extendedProps.confirmedWithOwner) {
        tooltipInstance = new Tooltip(info.el, {
          title: `<h4>${info.event._def.title}</h4>  ${info.event._def.extendedProps.notes}<br>Confirmed with Owner.`,
          html: true,
          placement: "top",
          trigger: "hover",
          container: "body",
          animation: true
        });
      } else {
        tooltipInstance = new Tooltip(info.el, {
          title: `<h4>${info.event._def.title}</h4>  ${info.event._def.extendedProps.notes}<br>Not Confirmed with Owner.`,
          html: true,
          placement: "top",
          trigger: "hover",
          container: "body",
          animation: true
        });
      }

      tooltipInstance.show();
    }
  };

  const handleMouseLeave = (info) => {
    if (tooltipInstance) {
      tooltipInstance.dispose();
      tooltipInstance = null;
    }
  };

  const fetchCal = async () => {
    const response = await fetch(`${configData.SERVER_URL}/api/events`, {
      headers: { Authorization: `Bearer ${user.token}` },
    });
    const json = await response.json();
    console.log('tick');
    if (response.ok) {
      dispatch({ type: "SET_CALENDAR", payload: json });
    }
  };

  useEffect(() => {
    setDisplay("popupHidden");

    const pullInterval = setInterval(fetchCal, 300000)

    const fetchProperties = async () => {
      const response = await fetch(`${configData.SERVER_URL}/api/properties`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();

      if (response.ok) {
        json.sort((a, b) => a.propAddress1.localeCompare(b.propAddress1));
        setLocationOptions(json);
      }
    };

    const fetchVendor = async () => {
      // console.log(user);
      // console.log(localStorage.getItem("user"));

      let localUser = JSON.parse(localStorage.getItem("user"));
      const res = await axios.get(`${configData.SERVER_URL}/api/vendors/` + path, {
        headers: {
          Authorization: `Bearer ${localUser.token}`,
        },
      });
      // console.log(res.data)
      res.data.vendor.notes = res.data.newAdminNotes
      // console.log(res.data.vendor)
      setEventVendor(res.data.vendor);
      console.log(vendors.vendorServices)
      setVendorServiceArray(vendors.vendorServices)
      setVendorServices(vendors.vendorServices)
      console.log(path);
    };
    const fetchServices = async () => {
      const response = await fetch(`${configData.SERVER_URL}/api/services`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();

      if (response.ok) {
        // console.log(json)
        serviceDispatch({ type: "SET_SERVICES", payload: json });
      }
    };

    //if (user) {
    fetchCal();
    fetchProperties()
    fetchVendor()
    fetchServices()
    return () => clearInterval(pullInterval);
    //}
  }, [serviceDispatch, dispatch, user]);

  const handleClick = async () => {
    if (!user) {
      return;
    }
    const response = await fetch(`${configData.SERVER_URL}/api/events/` + eventID, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();

    if (response.ok) {
      setDisplay("popupHidden");
      dispatch({ type: "DELETE_EVENT", payload: json });
    }
  };

  function findEvent() {
    console.log(locationOptions)
    if (eventID) {
      console.log(eventID);
      const foundEvent = calendar.find((event) => event._id === eventID);
      console.log(foundEvent);
      setEditEvent(true);
      setNewTitle(foundEvent.title);
      setNewLocation(foundEvent.location);
      setNewStart(foundEvent.start);
      setNewEnd(foundEvent.end);
      setNewDescription(foundEvent.notes);
      setNewEventVendor(foundEvent.vendor);
      setNewVendorServices(foundEvent.service);
    }
  }

  const handleUpdate = async (e) => {
    // e.preventDefault();
    if (newEvent) {
      e.preventDefault();
      setStatus("Sending...");

      var details = { 'title': newTitle, 'start': newStart, 'end': newEnd, 'location': newLocation, 'notes': newDescription, 'service': vendorServices, 'vendor': eventVendor, 'confirmedWithOwner': false };
      // console.log(details)
      let response = await fetch(`${configData.SERVER_URL}/api/events`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      });
      setStatus("Submit");
      const json = await response.json();
      if (!response.ok) {
        // alert(!response.ok)
        // console.log(json.error);
        setError(json.error);
        setEmptyFields(json.emptyFields);
        // console.log(emptyFields);
      }
      if (response.ok) {
        // alert(response.ok)
        // console.log(json);
        setNewTitle("");
        setNewStart("");
        setNewEnd("");
        setNewDescription('');
        setNewLocation('');
        setNewEventVendor('');
        setNewVendorServices('');
        setError(null);
        setEmptyFields([]);
        dispatch({ type: "CREATE_EVENT", payload: json });
        resetSelectElement();
        setEditEvent(false);
        setNewEvent(false);
        setDateClicked(false)
        setDisplay("popupHidden");
      }
    } else {
      console.log(newTitle, newStart, newEnd, newLocation, newDescription, eventID);
      setEditEvent(false);
      let title = newTitle;
      let start = newStart;
      let end = newEnd;
      let location = newLocation;
      let notes = newDescription;
      let vendor = newEventVendor;
      let service = newVendorServices;
      if (newOwnerConfirm === true) {
        var confirmedWithOwner = true;
      } else {
        var confirmedWithOwner = false;
      }

      const event = { title, start, end, location, notes, service, vendor, confirmedWithOwner };
      console.log(event);
      const response = await fetch(`${configData.SERVER_URL}/api/events/` + eventID, {
        method: "PUT",
        body: JSON.stringify(event),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();

      if (!response.ok) {
        setError(json.error);
        setEmptyFields(json.emptyFields);
        console.log(json.error);
      }
      if (response.ok) {
        console.log(json);
        setEditEvent(false);
        setTitle(newTitle);
        setLocation(newLocation);
        setStart(newStart);
        setEnd(newEnd);
        setEventVendor(newEventVendor);
        setVendorServices(newVendorServices);
        setError(null);
        setEmptyFields([]);
        setDescription(newDescription);
        setOwnerConfirmed(newOwnerConfirm)
        setDateClicked(false)
        dispatch({ type: "UPDATE_EVENT", payload: json });
      }
    }
  };
  // const date = info.date.toISOString()

  const handleServiceChange = (e) => {
    e.preventDefault();
    if (e.target.value) {
      let serviceArr = e.target.value.split(",");
      let serviceObj = { 'name': serviceArr[0], 'id': serviceArr[1] };
      setNewVendorServices([serviceArr[0], ...newVendorServices]);
      console.log(vendorServices)
    }
  };

  const handleVendorChange = (event) => {
    setNewEventVendor(event.target.value);
  };

  function resetSelectElement() {
    var dropDown = document.getElementById("location");
    dropDown.selectedIndex = 0;
  }

  if (vendors) {
    vendors.forEach((vendor) => {
      newVendorArray.push({ 'label': vendor.vendorName, 'value': vendor.vendorName })
    })
  }
  if (services) {
    services.forEach((service) => {
      newServicesArray.push({ 'label': service.serviceName, 'value': service.serviceName })
    })
    newServicesArray.sort((a, b) => a.label.localeCompare(b.label));
  }

  return (
    <div>
      {calendar ? (
        <div className='desktopCal'>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "timeGridWeek,timeGridDay,dayGridMonth",
            }}
            initialView="dayGridMonth"
            events={calendar}
            changeView="timeGridDay"
            // eventMouseEnter={function (arg) {
            //   setDisplay("popupActive");
            //   setEditEvent(false);
            //   setEventID(arg.event._def.extendedProps._id);
            //   setLocation(arg.event._def.extendedProps.location);
            //   setTitle(arg.event._def.title);
            //   setDescription(arg.event._def.extendedProps.notes);
            //   setEventVendor(arg.event._def.extendedProps.vendor);
            //   setVendorServices(arg.event._def.extendedProps.service);
            //   // console.log(arg.event)
            // }}
            // eventMouseLeave={() => setDisplay("popupHidden")}
            // eventDisplay="block"
            // eventDidMount={function (info) {
            //   var tooltip = new Tooltip(info, {
            //     title: 'howdy',
            //     placement: 'top',
            //     trigger: 'hover',
            //     container: 'body'
            //   });
            // }}
            eventMouseEnter={handleMouseEnter}
            eventMouseLeave={handleMouseLeave}
            eventClick={function (arg) {
              setDisplay("popupActive");
              setEditEvent(false);
              setEventID(arg.event._def.extendedProps._id);
              setLocation(arg.event._def.extendedProps.location);
              setTitle(arg.event._def.title);
              setDescription(arg.event._def.extendedProps.notes);
              setEventVendor(arg.event._def.extendedProps.vendor);
              setVendorServices(arg.event._def.extendedProps.service);
              setOwnerConfirmed(arg.event._def.extendedProps.ownerConfirmed);
              // console.log(arg.event)
            }}
            dateClick={function (info) {
              // document.getElementById("new_event").click();
              // $("#new_event").trigger("click", info.date);
              // document.getElementById("startDate").value = info.date.toISOString();
              setDisplay("popupActive");
              setEditEvent(true);
              setNewEvent(true);
              setNewTitle("");
              setNewDescription('');
              setNewEventVendor('');
              setNewVendorServices('');
              setNewLocation('');
              let date = info.date.toISOString()
              console.log(date)
              let formattedDate = date.slice(0, 16)
              setNewStart(formattedDate);
              setNewEnd(formattedDate);
              setNewOwnerConfirm(false)
              setDateClicked(true)
            }}
            editable='true'
            eventDrop={function (info) {
              console.log(info.event._instance.range.end)
              setNewStart(info.event.start);
              setNewEnd(info.event._instance.range.end);
              handleUpdate()
              // alert(info.event.title + " was dropped on " + info.event.start);
            }}
          />
        </div>
      ) : null}
      {calendar ? (
        <div className='mobileCal'>
          <FullCalendar
            plugins={[listPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next",
              center: "title",
              right: "today",
            }}
            initialView="listWeek"
            events={calendar}
            changeView="timeGridDay"
            eventClick={function (arg) {
              setDisplay("popupActive");
              setEditEvent(false);
              setEventID(arg.event._def.extendedProps._id);
              setLocation(arg.event._def.extendedProps.location);
              setTitle(arg.event._def.title);
              setDescription(arg.event._def.extendedProps.notes);
              setEventVendor(arg.event._def.extendedProps.vendor);
              setVendorServices(arg.event._def.extendedProps.service);
              setOwnerConfirmed(arg.event._def.extendedProps.ownerConfirmed);
              // console.log(arg.event)
            }}
          />
        </div>
      ) : null}
      <div className={display}>
        <div>
          {" "}
          {!editEvent ? (
            <div>
              <h3>{title}</h3>
              <p>{location}</p>
              {vendorServices.length !== 0 &&
                <ul>
                  {vendorServices.map((service) => <li>{service}</li>)}
                </ul>
              }
              <p>{eventVendor}</p>
              {user ? (
                <div>
                  <p>Description: {description}</p>
                  {ownerConfirmed && <p>Confirmed with Owners!</p>}
                  <span
                    class="material-symbols-outlined closePopup"
                    onClick={() => { setDisplay("popupHidden"); setDateClicked(false) }}
                  >
                    cancel
                  </span>
                  <div className="cal-popup-edit">
                    <span
                      class="material-symbols-outlined delete"
                      onClick={findEvent}
                    >
                      edit
                    </span>{" "}
                    <span
                      class="material-symbols-outlined delete"
                      onClick={handleClick}
                    >
                      delete
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <form className="editForm" onSubmit={handleUpdate}>
              <div className="single-label-group">
                <label className="new-prop-label-title" htmlFor="title">Title:</label>
                <input
                  type="text"
                  placeholder={newTitle}
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                />
              </div>
              <div className="single-label-group">
                <label className="new-prop-label-title" htmlFor="description">Description:</label>
                <textarea
                  type="text"
                  id="description"
                  rows="4" cols="50"
                  onChange={(e) => setNewDescription(e.target.value)}
                  value={newDescription}
                />
              </div>
              <div className="single-label-group">
                <label htmlFor="start">Start Date/Time:</label>
                <input
                  type="datetime-local"
                  id="startDateTime"
                  onChange={(e) => setNewStart(e.target.value)}
                  value={newStart}
                />
              </div>
              <div className="single-label-group">
                <label htmlFor="end">End Date/Time:</label>
                <input
                  type="datetime-local"
                  id="endDateTime"
                  min={newStart}

                  onChange={(e) => setNewEnd(e.target.value)}
                  value={newEnd}
                />
              </div>
              <div className="single-label-group">
                <label htmlFor="location">Location:</label>
                <select
                  id="location"
                  value={newLocation}
                  onChange={(e) => setNewLocation(e.target.value)}
                  className="new-prop-select"
                >
                  <option>Select Location</option>
                  {locationOptions ? locationOptions.map((lo) =>
                    <option className="new-prop-option" value={lo.propAddress1}>{lo.propAddress1}</option>
                  ) : <option>No Locations Found</option>}
                </select>
              </div>
              <div className="multi-label-group">
                <div>
                  <label className="new-prop-label-title" htmlFor="description">Vendor:</label>
                  <PropDropdown
                    options={newVendorArray}
                    value={newEventVendor}
                    onChange={handleVendorChange}
                  />
                  <p>{newEventVendor}</p>
                </div>
                <div>
                  <label className="new-prop-label-title" htmlFor="description">Services:</label>
                  <PropDropdown
                    options={newServicesArray}
                    value={newVendorServices}
                    onChange={handleServiceChange}
                  />
                  <PropList
                    optionSet={newVendorServices}
                  />
                </div>
              </div>
              {!dateClicked &&
                <div>
                  {ownerConfirmed ? (
                    <div>
                      <input type="checkbox" id="ownerConfirmed" name="ownerConfirmed" value='true' onChange={(e) => setNewOwnerConfirm(e.target.value)} checked />
                      <label for="ownerConfirmed">Confirmed with Owners</label>
                    </div>
                  ) : (
                    <div>
                      <input type="checkbox" id="ownerConfirmed" name="ownerConfirmed" onChange={handleCheckChange} />
                      <label for="ownerConfirmed">Confirmed with Owners</label>
                    </div>
                  )}
                </div>
              }
              <div>
                <span
                  class="material-symbols-outlined closePopup"
                  onClick={() => {
                    setEditEvent(false)
                    if (newEvent) {
                      setNewTitle("");
                      setNewStart("");
                      setNewEnd("");
                      setNewDescription('');
                      setNewEventVendor('');
                      setNewVendorServices('');
                      setNewLocation('');
                      setNewOwnerConfirm(false)
                      setError(null);
                      setEmptyFields([]);
                      resetSelectElement();
                      setEditEvent(false);
                      setNewEvent(false);
                      setDateClicked(false)
                      setDisplay("popupHidden");
                    }
                  }}
                >
                  cancel
                </span>
              </div>
              {newEvent ?
                <button>{status}</button>
                :
                <button>Submit Changes</button>
              }
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarComponent;
