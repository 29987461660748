import { OwnersContext } from "../context/OwnersContext";
import { useContext } from "react";

export const useOwnersContext = () => {
  const context = useContext(OwnersContext);

  if (!context) {
    throw Error(
      "useOwnersContext must be used inside OwnersContextProvider"
    );
  }

  return context;
};